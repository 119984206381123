@import '../../Assets/Styles/variables.scss';

.aboutBanner{
    background: url('../Image/app-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    section, h1, p{color: $black100!important;}
    &::before {
        content: "";
       // background: linear-gradient(90deg, rgba(31, 27, 169, 0.3) 0%, rgba(8, 72, 86, 0) 72%);
        background: linear-gradient(90deg, rgba(31, 27, 169, 0.3) 0%, rgba(8, 72, 86, .7) 72%);
        background: linear-gradient(160deg, rgba(0, 0, 0, 0.5) 0%, rgba(2, 142, 212, 0.8) 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
      color: $white100!important;
      .container{
        padding:50px 20px 30px 20px!important;
        position: relative;
        z-index: 2;
        h1{
            //font-size: 24px!important;
            // text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
            color: $white100!important;
          }
          h2{
            //font-size: 20px!important;
            //text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            color: #fff!important;
            font-weight: 700;
          }
      }
     
}

.about-banner {
    background: url(../Image/app-bg.jpg) no-repeat center center;
    background-size: cover; 
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 8;
        background: linear-gradient(99.44deg, rgba(0, 71, 76, 0.68) 0.19%, rgba(0, 77, 82, 0.35) 95.35%);
    }
    .container {
        position: relative;
        z-index: 9;
        color: $white100;
    }
    h1 {
        font-weight: 600;
        font-size: 24px !important;
        color: $white100 !important;
      }
    p {
        font-size: 15px;
    }
    .count-section {
        i {
            font-size: 24px;
        }
        h4, i {
            color: $white100;
        }
    }
}
.about-section {
    background-color: $white100;  
    .whitecard {
        background-color: $white100;
    }
    h2 {
        margin: 10px 0 5px;
        i {
            font-size: 18px;
            color: var(--primary100);
        }
    }
    p {
        font-size: 16px;
    }
}
.about-wrapper {
    .why-us-wrapper {
        background: url('../Image/money-bg.jpg') no-repeat center center;
        background-size: cover;
        &::before {
            background: linear-gradient(180deg, #FFFFFF 47.53%, rgba(255, 255, 255, 0.52) 100%);
        }
        .why-us-box {
            max-width: 600px;
        }
        h2 {
            font-size: 18px !important;
        }
    }
}

.mainWrapper {
    h1, h2 {
      font-weight: 600;
    }
    h3 {
      font-weight: 500;
    }
  }