@import "./variables.scss";
@import "./mixins.scss";

/* Login CSS */
.login-wrapper-main,
.login-wrapper-main.mainWrapper {
    background: url('../Images/login-bg.jpg') no-repeat 0 0 !important;
    background: url('../Images/home-banner.jpeg') bottom center !important;
    background-size: cover !important;  
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0; 
        bottom: 0;
        width: 100%;
        z-index: 8;
        background: linear-gradient(0deg, rgba(1,135,73,0.5) 0%, rgba(1,135,73,0.05) 100%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(2, 142, 212, 0.5) 100%);
    }
    header {
        background: transparent !important;
    }
}
.login-wrapper {    
    position: relative;
    //background: url('../Images/patternbg.png') no-repeat center center;
    h1 {
        text-align: center;
    }
    a { 
        
        color: var(--primary100);
        text-decoration: underline;
        &:hover,
        &:focus {
            color: var(--primary200);
        }
    } 
    .login-box {
        padding: 10px 0;
        font-size: 12px;
        position: relative;
        z-index: 9;
        // .login-header-icon {
        //     top: -50px;
        //     z-index: 9;
        // }
        p {
            font-size: 20px;
            font-weight: 400;
            color: $grey75;
        }
        .logo-wrapper {
            img {
                width: 50px;
            }
        }
        .form-check-input {
            width: 14px;
            height: 14px;
            margin: 3px 10px 0 0;
            vertical-align: middle;
            border: 1px solid $grey100;
            border-radius: 3px;
            &:checked {
                background-color: var(--primary50);
                border-color: var(--primary50);
            }
            .form-check-label,
            .forgot-password a {
                color: $grey100;
                line-height: normal;
            } 
            .forgot-password {
                a { 
                    line-height: 22px;
                    color: var(--primary50);
                    &:hover,
                    &:focus {
                        color: var(--primary200);
                    }
                } 
            }
        }
        .form-check-label {
            span{
                    color: var(--primary100); 
            }
        }
        .forgot-password {
            a { 
                line-height: 22px;
                font-size: 12px;
                color: var(--primary100);
                text-decoration: underline;
                &:hover,
                &:focus {
                    color: var(--primary200);
                    text-decoration: none;
                }
            } 
        }
        .btn {          
            //width: 100% !important;
            margin: 0;
            padding: 2px 20px !important;
            height: 30px !important;
            margin: 0px 5px;
            &:focus,
            &:active,
            &:visited,
            &:active{
                background-color: transparent !important;
                color: var(--primary100)!important;
            }
            &.w-50 {
                width: 50% !important;
            }
        }
        .btn.btn-sm,
        .btn.change, .btn.resend {
            font-size: 12px !important;
        }
        .btn-primary {
            &:disabled {
                color: $white100; 
            }
        }
        .userType {
            .btn {
                padding: 5px 15px !important;
                margin: 0px!important;
            }
        }
        small {
            font-size: 12px;
        }
    }
}
.form-wrapper {
    .light-text {
        line-height: normal;
        font-size: 14px;
    }
}
.signin-container {
    width: 370px;
    min-height: 350px;
    max-width: 100%;
    box-shadow: 8px 0px 16px 0px #0D0A2C2B;
    border-radius: 10px !important;
    background: $white100;
    h1 {
        color: $grey75; 
        font-size: 1.9rem;
        font-weight: 600;
    }
    p { 
        font-size: 14px;
        line-height: normal;
    }
}
@media (max-width: 767px) {
    .login-wrapper {
        .login-left {
            min-height: 350px;
        }
        .login-right {
            padding: 20px 20px 35px;
            .btn {
                min-width: 100% !important;
            }      
        }
    }
    .signin-container {
        .form-wrapper {
            max-width: 100%;
        } 
        h1 { 
            line-height: 34px;    
            font-size: 1.8rem;
        }
    }
    .mainTopHeader {
        ul {
            li { 
                padding: 0 !important;
            }
        }
    }
}