header{
    padding: 5px 10px;
    position: relative;
    z-index: 1;
    color: $white100;
    .navbar {
        .navbar-nav {
            .nav-link {
                padding: 15px 12px;
            }
        }
    }
    .nav-link, a:link, a:visited, a:hover{
        color: $white100 !important;
        text-decoration: none;
        font-size: 15px;
    }
    .nav-link:hover{
        text-decoration: underline;
    }
    .btnLink{
        padding: 0px 5px;
        color: $white100 !important;
        text-decoration: none;
        font-size: 14px!important;
    }
}