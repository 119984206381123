@import "./variables.scss";
@import "./mixins.scss";

.mainWrapper{
  background-color: $white100;
  h1 {
    font-size: 26px !important;
    font-weight: 400;
  }
  h2 {
    font-size: 22px !important;
    font-weight: 300;
    // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
  h3 {
    font-size: 18px !important;
  }

  section.pt-0{
    padding-top: 0px;
  }
  section.pb-0{
    padding-bottom: 0px;
  }
}
.home{
  section{
    padding: 80px 0px;
  }
}
.container-xl{
  max-width: 100% !important;
}

.layout-boxed {
  html,
  body {
    height: 100%;
  }
}
.adminContainer {
  background-color: #f0f0f0;
}
.pageWrapper {
  padding: 50px 10px 12px !important;
  min-height: calc(100vh - 53px) !important;
  .pageWrapper {
    box-shadow: none;
    padding: 0px !important;
    min-height: auto !important;
  } 
} 
.modal {
  .pageWrapper {
    box-shadow: none;
    padding: 0px !important;
    min-height: auto !important;
  } 
}
.wrapperHeight,
.login-wrapper {
  min-height: calc(100vh - 104px) !important;
}
.page-wrapper{
  min-height: calc(100vh - 53px) !important; 
  background-color: #ffffff;
}
.main-wrapper {
  min-height: calc(100vh - 365px);
}
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.layout-boxed {
  .wrapper {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 100%;
    box-shadow: 0 0 8px $black25;
    position: relative;
  }
}
.po-wrapper {
  .content-wrapper {
    margin-left: 0;
  }
  .page-wrapper,
  .content-wrapper {
    min-height: calc(100vh - 105px);
  }
}
.smallHeadTxt {
  @include heading-scale(14px, #505b5a, 400);
}
.fixed {
  .main-header,
  .main-sidebar,
  .left-side {
    position: fixed;
  }
  .main-header {
    top: 0;
    right: 0;
    left: 0;
  }
  .content-wrapper,
  .right-side {
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .fixed {
    .content-wrapper,
    .right-side {
      padding-top: 100px;
    }
  }
}
.fixed.layout-boxed {
  .wrapper {
    max-width: 100%;
  }
}
.fixed {
  .wrapper {
    overflow: hidden;
  }
}
.hold-transition {
  .content-wrapper,
  .right-side,
  .main-footer,
  .main-sidebar,
  .left-side,
  .main-header .navbar,
  .main-header .logo,
  .menu-open .fa-angle-left {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.content-wrapper {
  padding: 0;
  min-height: calc(100vh - 120px);
  // background-color: #fafafa;
}
.startOuter {
  background-color: $white100;
  padding: 15px;
}

@media (min-width: 768px) {
  .content-wrapper {
    // overflow-x: auto;
  }
  .main-wrapper {
    min-width: 900px;
  }
  .po-wrapper {
    .content-wrapper {
      overflow-x: none;
    }
  }
}

.skin-blue .sidebar-menu > li > .treeview-menu{
  padding-left: 15px !important;
}
 
.sidebar-menu{
  max-height: calc(100vh - 100px);
  overflow-y: auto !important;
  padding-bottom: 100px !important;
}

.dashboard-content {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8 {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.home.mainWrapper{
  .header{
    background: transparent!important;
  }
}