@import 'variables.scss';

.homepage {
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 700;
  }
  h3 {
    font-size: 18px !important;
    font-weight: 500;
  }
}
.mainWrapper{
.homeBanner {
  padding: 0px;
  background: url('../../Assets/Images/home-banner.jpeg') bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(90deg, rgba(31, 27, 169, 0.3) 0%, rgba(8, 72, 86, 0.3) 72%);
    background: linear-gradient(150deg, rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 30%) 72%);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .carousel-item {
    transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  }
  .header{
    background-color: transparent;
  }
}
.slides {
  color: $white100;
  height: calc(100vh - 200px);
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35354b;
  .container {
    position: relative;
    //padding: 10px;
    z-index: 9999999;
  }
  h1{
    color: #35354b;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  }
  h4, h2 {
    color: $white100;
    color: #35354b;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px !important;
    li {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}
h2{
  font-size: 24px!important;
  color: #35354b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}
}


.home-banner-section {
  .carousel-item {
    position: relative;
    max-height: 480px; 
    &::before {
      content: "";
      //background: linear-gradient(90deg, var(--primary100) 0%, rgba(255, 255, 255, 0) 70%);
      background: linear-gradient(90deg, #254C55 0%, rgba(8, 72, 86, 0) 72%);
      //opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: cover;
    }
  }
  .carousel-indicators [data-bs-target] {
    background-color: $white100;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 0;
  }
  .carousel-caption {
    text-align: left;
    color: $white100;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    bottom: 27%;
    left: 8%;
    z-index: 9;
    .highlight {
      color: var(--primary100);
    }
    p {
      font-weight: 500;
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

.features-section {
  background-color: var(--primary15);
  box-shadow: 0px 2px 5px 0px #0000000D;
  i {
    color: var(--primary100);
    font-size: 28px;
    // &::before {
    //   vertical-align: middle;
    // }
  }
}
  
.unlocking-potential-section {
  background: url('./Image/buildingbg.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  &::before {
    content: "";
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(217, 217, 217, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .yellowbox {
    width: 70px;
    height: 70px;
    background-color: var(--primary25);
    i {
      color: var(--primary100);
      font-size: 26px;
      &::before {
        vertical-align: middle;
      }
    }
  }
}

.feature-box {
  background: #E0E0E0;
  border-radius: 15px;
}

.start-investment-section {
  background: url('./Image/investmentbg.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 80px 0px;
  &::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 101, 114, 0.6) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    z-index: 2;
    //color: $white100;
    .book-appointment-box {
    //   box-shadow: 4px 4px 4px 0px #00000040;
    //   border-radius: 20px;
    //   background: #E0E0E0;
    //   max-width: 460px;
    //   h3 {
    //     color: $black100;
    //   }
    box-shadow: 4px 4px 4px 0px #00000040;
    border: $white100 solid 5px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.90);
    backdrop-filter: blur(10px);
    max-width: 460px;
     
    }
  }
  h2{
    color: #FFFFFF;
  }
}

.count-section {
  box-shadow: 0px 2px 5px 0px #0000000D;
  .bi {
    font-size: 40px !important;
    color: var(--primary100);
  }
  h4{
    font-size: 20px !important;
    color: var(--primary100);
  }
}

.download-app-section {
  background: url('./Image/app-bg.jpg') no-repeat center center;
  background-size: cover;
  padding-bottom: 0px!important;
  position: relative;
  &::before {
    content: "";
    background: linear-gradient(94.5deg, rgba(12, 130, 157, 0.71) 0%, rgba(5, 7, 7, 0.48) 98.35%);
   // background: linear-gradient(180deg, rgba(40, 57, 116, 0.8) 0%, rgba(4, 96, 108, 0.6) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    z-index: 2;
    color: $white100;
  }
  h2, h3{
    color: #ffffff;
  }
}

.mfdHome{
  .homeBanner{
    background: url('./Image/heroslider/banner1.jpg') bottom center;
  }
}
.mfdHome, .riaHome, .raHome{
  .homeBanner{
    //background: url('../Image/heroslider/banner1.jpg') bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  
    .logo-text{
      color: #ffffff!important;
    }
  }
  .owl-carousel .owl-stage-outer{
    padding-top: 15px;
  }

  .main-header.mainTopHeader .dropdown.nav-item a, 
  .mainWrapper .main-header.mainTopHeader .navbar .navbar-nav .nav-link,
  .main-header .navbar .navbar-nav .nav-link  {
    //color: #000 !important;
  }
  .main-header.mainTopHeader .dropdown.nav-item {
    .dropdown-menu {
      li {
        .dropdown-item {
          color: #000 !important;
          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }


}
.mfdHome{
  .homeBanner{
    &::before {
      content: "";
      //background: linear-gradient(150deg, rgba(44, 83, 120, 0.669) 0%, rgba(8, 72, 86, 0.3) 72%) !important;
      //background: linear-gradient(180deg, rgb(197 208 219 / 80%) 0%, rgba(8, 72, 86, 0.3) 60%) !important;
      background: linear-gradient(180deg, rgb(197 208 219 / 0) 0%, rgba(8, 72, 86, 0) 60%) !important;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .text-white{
      p, h1{
        color: #ffffff!important;
      }
    }
  }
}


.unlocking-potential-section .yellowbox {
  width: 70px;
  height: 70px;
  background-color: var(--primary25);

  background: var(--primary25);
  border: 1px solid #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;

  display: inline-block;
  vertical-align: middle;
  padding-top: 0px;
  font-size: 20px;
  margin-right: 10px;
}

.testimonial-card {
  border: 2px solid $white100;
  background: $white100;
  box-shadow: 1px 1px 10px 0px #00000026;
  border-radius: 10px;
  min-height: 170px;
  padding: 0px 30px 30px;
  font-size: 12px;
margin: 10px;
  .testimonial-author {
    margin-bottom: 15px;
    .author-img {
      position: relative;
      top: -20px;
      left: 15px;
      z-index: 1;

      img {
        width: 80px;
        height: 80px;
        box-shadow: 1px 1px 10px 0px #00000026;
        border-radius: 50px;
        border: 2px solid $white100;
        background-color: $white100;
      }
    }

    h3 {
      color: var(--primary100);
      font-size: 18px !important;
    }

    p {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .testimonial-content {
    font-size: 14px;
    font-weight: 200!important;
    font-style: italic;
    position: relative;
    top: -8px;
    z-index: 1;
  }

  .quote {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
  }
}

@media (max-width: 767.98px) { 

  .mfdHome .homeBanner {
      //background: url('../../Assets/Images/home-banner.jpeg')  63% 0;
  }
}