@import "../../Assets/Styles/variables.scss";

#ria-banner-slider {
  .carousel-item {
    &::before {
      background: linear-gradient(90deg, #236494 0%, rgba(35, 100, 148, 0) 72%);
    }
  }
} 

#appDownload {
  background-image: url(../../Assets/Images/app-download-bg.jpg);
  &::before {
    background: linear-gradient(
      94.5deg,
      rgba(0, 71, 154, 0.5) 0%,
      rgba(34, 82, 82, 0.48) 98.35%
    );
  }
}

.riaMainWrapper {
  // font-family: $font-family-roboto;
  font-weight: 400;
  .homeBanner {
    padding: 0px;
    background: url("../../Assets/Images/slider/riabanner.jpg") bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 550px;
    height: calc(100vh - 100px);

    &::before {
      content: "";
      background: transparent !important;
      // background: linear-gradient(90deg, #236494, #23649400 72%);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .carousel {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 9;
      max-width: 565px;
      transform: translate(10%, -75%);
      .carousel-item {
        transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
        position: relative;
        //max-height: 480px;

        img {
          object-fit: cover;
        }
      }
      .slides {
        height: auto;
        min-height: auto;
        display: block;
        color: $riathemecolordark;
        h1 {
          color: $riathemecolordark;
          font-weight: 700;
          font-style: italic;
          font-size: 30px !important;
        }
        p {
          font-size: 16px;
          font-weight: 300;
          color: $black100;
        }
      }
      .carousel-indicators {
        button {
          background: #8b8b8b;
          &.active {
            background: $riathemecolor;
          }
        }
        [data-bs-target] {
          border-top-width: 0px;
          border-bottom-width: 0px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-bottom: 0;
        }
      }
    }
  }
  &.riaHome {
    .homeBanner {
      //background: url('../../Assets/Images/slider/riabanner.jpg') 0 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  #offerings {
    background: $white100;
    .featurebox {
      margin-top: 30px;
      padding: 30px;
      border: 1px solid #ffffff;
      border-radius: 0;
      height: auto;
      background: $riathemecolor;
      color: $white100;
      position: relative;
      &:hover {
        .perform-overlay {
          opacity: 1;
          height: 100%;
        }
      }
      .perform-overlay {
        border-radius: 14px;
        background: $riathemecolor;
        color: $white100;
        opacity: 0;
        transition: all 1s;
        -webkit-transition: all 1s;
        height: 0;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
        .btn {
          &.btn-secondary {
            background-color: $white100 !important;
            border-color: $riathemecolor !important;
            color: $riathemecolor !important;
            border-radius: 0 !important;
            &:hover {
              background-color: $riathemecolor !important;
              color: $white100 !important;
              border-color: $white100 !important;
            }
          }
        }
      }
      p {
        min-height: 20px;
      }
      .featureicon {
        width: 90px;
        height: 90px;
        border-radius: 60px;
        font-size: 46px;
        border: 1px solid $white100;
        color: $white100;
        background: rgba(255, 255, 255, 0.5);
        margin: 0 auto 20px;
        position: static;
        transform: translate(0px, 0px);
      }
    }
  }
  .featurebox {
    .perform-overlay {
      height: 0;
      padding: 0;
    }

    &:hover {
      .featuredtls {
        position: relative;
        z-index: 9;
        //color: $black100
      }

      .perform-overlay {
        height: 100%;
      }

      a {
        color: $white100;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $white100;
        }
      }
    }
  }
  .count-section {
    background: $white100;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    .bi {
      color: $riathemecolordark;
      font-size: 30px !important;
    }
    h4 {
      color: $black100;
      font-weight: 500;
      font-size: 20px !important;
    }
  }
  .helping-investment-text {
    background-color: $riathemecolorlight;
    p {
      line-height: 28px;
      font-size: 16px;
    }
  }
  .count-section-wrapper {
    bottom: -44px;
    z-index: 9;
  }
  .testimonials-section {
    background: rgba(242, 241, 241, 0.2);
    .testimonial-card {
      border: 5px solid $white100;
      background: $white100;
      box-shadow: none;
      border-radius: 0;
      padding: 15px 30px 25px;
      font-size: 12px;
      background: $riathemecolorlight;
      width: calc(100% - 50px);
      .testimonial-author {
        .author-img {
          top: 0;
          left: 0;
          img {
            border-radius: 0;
            box-shadow: none;
            width: 70px;
            height: 70px;
            border: 3px solid $white100;
          }
        }
        h3 {
          color: $riathemecolordark;
          font-weight: 500 !important;
          font-size: 16px !important;
        }
      }
      .testimonial-content {
        font-weight: 300 !important;
        font-style: normal;
        top: 0;
      }
    }
    .quote {
      width: 40px;
      height: 40px;
      background: $riathemecolor;
      color: $white100;
      font-size: 26px;
      font-weight: 300;
      padding: 10px;
    }
    .owl-carousel {
      .owl-dots {
        padding-top: 0;
        margin: 0 !important;
        .owl-dot {
          span {
            margin: 3px;
          }
          &.active {
            span {
              background: $riathemecolor;
            }
          }
        }
      }
    }
  }
  #focusGoals {
    background: url(../../Assets/Images/appointment.jpg) no-repeat 0 0;
    background-size: cover;

    &::before {
      background: transparent;
    }

    h2 {
      color: $riathemecolordark;
    }

    p {
      color: $black100;
    }
  }
  .homeBanner,
  .aboutBanner,
  .contactBanner,
  .toolsBanner {
    .main-header {
      background-color: $white100 !important;
      .logo {
        .logo-text {
          color: $black100 !important;
        }
      }
      .navbar {
        .navbar-nav {
          .nav-link {
            font-weight: 300 !important;
          }
          .nav-item {
            &.dropdown {
              a {
                font-weight: 300 !important;
              }
            }
          }
        }
      }
    }
  }
  .nav-tabs {
    .nav-item {
      .nav-link.active {
        color: $riathemecolor;
        border-color: $riathemecolor;
      }
    }
  }
  .main-footer {
    background-color: $riathemecolordark !important;
  }
  h1,
  h2 {
    color: $riathemecolordark;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  }
  h2 {
    font-size: 30px !important;
  }
  .title-border {
    position: relative;
    padding-bottom: 10px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 9;
      transform: translateX(-50%);
      width: 200px;
      border-bottom: 1px solid $riathemecolorgreen;
    }
  }
  .btn {
    &.btn-primary {
      background-color: $riathemecolor !important;
      border-color: $riathemecolor !important;
      color: $white100 !important;
      border-radius: 0 !important;
      &:hover {
        background-color: $riathemecolordark !important;
      }
    }
    &.btn-secondary {
      background-color: $white100 !important;
      border-color: $riathemecolor !important;
      color: $riathemecolor !important;
      border-radius: 0 !important;
      &:hover {
        background-color: $riathemecolor !important;
      }
    }
  }
}

.solutions-section {
  background-color: #f9f9f9;
  .solutionsCard {
    height: 100%;
    border: rgba(0, 0, 0, 0.05) solid 2px;
    padding: 10px;
    border-radius: 0px;
    margin: 10px 0px;
    background-color: $white100;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    align-items: center;
    h3 {
      font-size: 22px !important;
      font-weight: 500;
      color: $riathemecolordark;
    }
    p {
      font-weight: 400;
    }
    .topDiv {
      img {
        width: 100%;
      }
    }
    .bottomDiv {
      font-size: 16px;
      font-weight: 200;
      padding: 20px;
    }
  }
}

.services-section {
  .services-tabs-wrapper {
    .nav-tabs {
      background-color: #f8f8f8;
      display: none;
      border-bottom: 0;
      .nav-item {
        .nav-link {
          color: #919392;
          width: 100%;
          text-align: left;
          border-bottom: 1px solid #ddd;
          border-left: 0;
          border-top: 0;
          padding: 8px 20px !important;
          &.active {
            color: $black100;
            .bi {
              color: $riathemecolordark;
            }
          }
          &:hover {
            &.active {
              border-bottom: 1px solid #ddd;
              border-right: 1px solid #056B44;
            }
          }
          .bi {
            font-size: 18px;
            vertical-align: middle;
          }
        }
        &:last-child {
          .nav-link {
            border-bottom: 0 !important;
          }
        }
      }
    }
    .accordion {
      background-color: $riathemecolorlight;
      position: relative;
      .accordion-item {
        border: 0;
        background-color: $riathemecolorlight;        
      }
      .accordion-body {
        padding: 15px 100px 8px 25px;
        h3 {
          font-weight: 500;
        }
        ul {
          margin-bottom: 0;
          list-style-type: disc;
          padding-left: 25px !important;
        }
        .bgIcon {
          position: absolute;
          bottom: 5px;
          right: 35px;
          z-index: 9;
          .bi {
            font-size: 60px;
            color: #d5eae2;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .services-section {
    .nav-tabs {
      display: flex;
      flex-flow: column nowrap;
    }
    .nav-tabs {
      display: flex;
    }
    .nav-tabs .nav-item + .nav-item {
      margin-top: 0.25rem;
    }
    .nav-tabs .nav-link {
      transition: border-color 0.125s ease-in;
      white-space: nowrap;
    }
    .nav-tabs .nav-link:hover {
      background-color: #f7f7f7;
      border-color: transparent;
    }
    .nav-tabs .nav-link.active {
      border-bottom-color: #ddd;
      border-right-color: #fff;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      margin-right: -1px;
    }
    .nav-tabs .nav-link.active:hover {
      background-color: #fff;
      border-color: #0275d8 #fff #0275d8 #0275d8;
    }

    .card {
      border: none;
    }

    .card .card-header {
      display: none;
    }

    .card .collapse {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .services-section {
    .tab-pane {
      display: block !important;
      opacity: 1;
    }
    .bgIcon {
      display: none;
    }
  }
}
