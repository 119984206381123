@import "./variables.scss";
@import "./mixins.scss";

.hidden {
  display: none;
}
.visible {
  display: block;
}
.dashbordHeadings {
  font-size: 14px !important;
  color: $black100;
  margin: 5px 0px 3px;
  a {
    text-decoration: none;
  }
}
.whitebg {
  background-color: $white100;
}
.bg-box {
  box-shadow: 0 0 5px #ddd;
  background: $primary05;
}
.card {
  border-radius: 4px !important;
}
.text-blue,
.text-theme-color {
  color: var(--primary100);
}
.bg-primary-light {
  background: var(--primary25);
}
.bg-white-shadow {
  background-color: $white100;
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  .bg-white-shadow {
    box-shadow: none;
  }
}
.bgWhite {
  background: $white100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.gradientBg {
  background: $gradient;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
ol,
ul {
  list-style-type: none;
  padding-left: 0rem !important;
}
.whitecard {
  border-radius: 4px;
  //border: $grey25 solid 1px;
  overflow: hidden;
  border: #dee2e6 solid 1px;
  box-shadow: 0px 2px 4px 0px #00000014;
}
.cardOuterS {
  background: $gradient;
  border-radius: 4px;
}
.networth,
.value {
  font-family: $font-family-roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.HeaderMainS {
  background: linear-gradient(90deg, var(--primary100) 0%, var(--primary100) 99.99%);
}
.cardOuterS {
  background: linear-gradient(
    90deg,
    var(--primary100) 0%,
    var(--primary100) 99.99%,
    $white100 100%
  );
  border-radius: 4px;
}
.HeaderMainS {
  background: linear-gradient(90deg, var(--primary100) 0%, var(--primary100) 99.99%);
}
.cardOuterS {
  background: linear-gradient(
    90deg,
    var(--primary100) 0%,
    var(--primary100) 99.99%,
    $white100 100%
  );
  border-radius: 4px;
}

.investor-reg-section {
  border: 1px solid rgba(155, 155, 155, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  h2 {
    line-height: normal;
    color: var(--primary50);
  }
  h3 {
    text-transform: none;
    color: $grey300;
    line-height: normal;
  }
}
.onboarding-status,
.debit-mandate {
  border-right: 2px solid #cdd6da99;
  min-width: 24%;
}
.onboarding-status {
  i {
    color: var(--primary50);
    font-size: 3.3rem;
    &::before {
      vertical-align: middle;
    }
  }
}
.investor-reg-dtls {
  min-width: 54%;
}
.ir-img {
  min-width: 22%;
  img {
    bottom: -8px;
    z-index: 9;
    max-height: 140px;
  }
}
.complete-risk-profile.investor-reg-section {
  h2 {
    color: $black100;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.7;
  filter: invert(0.5);
  font-size: 22px;
}

@media (max-width: 991px) {
  .investor-reg-inner {
    display: block !important;
  }
  .onboarding-status,
  .debit-mandate {
    border-right: 0;
  }
  .ir-img {
    text-align: center;
  }
}
.pageTitle {
  font-size: 22px;
  color: $black100;
}
.pageHeading {
  font-size: 36px;
  color: $black100;
}
.panHeadingAll {
  font-size: 36px;
  line-height: 42px;
  color: var(--primary100);
}
.thanksInterrest {
  .bi {
    color: var(--primary50);
    font-size: 34px;
  }
}
.body-bottom {
  .form-check-label {
    font-size: 14px;
    color: var(--primary100);
  }
}
hr {
  border-color: rgba(205, 214, 218, 0.6);
}
.sticky-top-tabs {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #f8f9fa;
  max-width: 1095px;
}
img {
  max-width: 100%;
}
.upload-spacing {
  .uploadFileSn {
    margin-top: 5px;
  }
}
.bottom-notes {
  font-size: 14px;
  font-style: italic;
  line-height: normal;
  color: #505b5a;
  ul {
    list-style-type: decimal;
    padding-left: 20px;
  }
}
.modal-confirm-pan {
  h3 {
    color: var(--primary50);
  }
  p {
    line-height: normal;
  }
}
.downloadReport {
  .btn {
    i {
      font-size: 16px;
    }
  }
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  background-color: var(--primary50);
}
@media (max-width: 767px) {
  .panAllModal {
    .panHeadingAll {
      font-size: 20px;
      line-height: 25px;
    }
    .closeModal {
      top: 4px;
    }
  }
  .sticky-top-tabs {
    max-width: 280px;
  }
  .investor-reg-section {
    h2 {
      font-size: 1.5rem;
    }
  }
}
.init {
  .bi-exclamation-circle-fill {
    font-size: 50px;
  }
}
.download-app {
  li {
    a {
      font-size: 20px;
      color: $white100;
    }
  }
}
.upload-cas-pdf {
  .uploadFileSn {
    margin-top: 0;
  }
}
.ij-graph-title {
  font-size: 14px;
  .graph-bullet {
    height: 12px;
    width: 12px;
    display: inline-block;
    &.green-bullet {
      background: var(--primary50);
    }
    &.black-bullet {
      background: $black100;
    }
  }
}
.text-justify {
  text-align: justify;
}
.content-page {
  h5 {
    font-size: 16px;
  }
  p,
  li {
    font-size: 15px;
  }
}
.min-width-auto {
  min-width: auto !important;
}
.line-height-normal {
  line-height: normal;
}
.table-action-dp {
  .btn {
    background: $transparent !important;
    border: 0 !important;
    min-width: 10px !important;
    padding: 0 5px !important;
    height: 20px !important;
    &::after {
      display: none !important;
    }
  }
  .dropdown-menu {
    top: 0px !important;
    li {
      padding: 0;
      background: $white100;
    }
  }
  .dropdown-item {
    font-size: 12px;
    padding: 2px 12px 2px 5px;
    background: $white100;
    .bi {
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
.no-data {
  font-size: 12px;
  font-weight: 500;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: $grey100 !important;
}
.btnDiv {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.nowrap {
  white-space: nowrap;
}
.tab-pane {
  padding: 10px;
}
.text-left,
td.text-left {
  text-align: left !important;
}
.tab-pane {
  .formgroup {
    margin-top: 10px;
  }
  label.label-name {
    min-height: 35px;
    margin-bottom: 0px;
  }
}

.divRight {
  text-align: right;
  float: right;
}

.flex-1 {
  flex: 1;
}

.note-txt,
.messageOTP {
  padding-top: 10px;
  font-size: 12px;
}

.title {
  small {
    color: $grey100;
  }
}
.border-left {
  border-left: 2px solid var(--primary50);
}
.color-yellow {
  color: var(--primary50);
}
.pl-2 {
  padding-left: 20px;
}
.RightDateText {
  font-size: 14px;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.pagetitletop {
  display: block;
  float: left;
  width: 100%;
}
.container h4.headingtext {
  font-size: 20px;
  font-weight: 600;
}

.acountdetailtable thead,
.acountdetailtable tbody {
  display: contents;
}
.pagetitletop .title {
  float: left;
}
.acountdetailtable thead tr {
  background: rgba(38, 50, 56, 0.05);
  border-bottom: 1px solid var(--primary50);
}
.acountdetailtable tbody tr {
  border-bottom: 1px solid $grey700;
}
.acountdetailtable tbody tr:last-child {
  border-color: transparent;
}
.acountdetailtable tbody tr:nth-child(even) {
  background-color: rgba(38, 50, 56, 0.02);
}

.container h4.title-profile {
  color: $black400;
  font-weight: 500;
  font-size: 20px;
  width: auto;
  float: left;
}

.acountdetailtable thead,
.acountdetailtable tbody {
  display: contents;
}
.pagetitletop .title {
  float: left;
}
.acountdetailtable thead tr {
  background: rgba(38, 50, 56, 0.05);
  border-bottom: 1px solid var(--primary50);
}
.acountdetailtable tbody tr {
  border-bottom: 1px solid $grey700;
}
.acountdetailtable tbody tr:last-child {
  border-color: $transparent;
}
.acountdetailtable tbody tr:nth-child(even) {
  background-color: rgba(38, 50, 56, 0.02);
}

#FinplaningTab-tabpane-assumptions button {
  min-width: 100px !important;
  height: 36px !important;
}
#FinplaningTab-tabpane-assumptions.active button.addbtn.btn-primary {
  min-width: 70px !important;
  height: 32px !important;
}

.btnarea {
  display: flex;
  justify-content: left;
  align-items: end;
}
.plusicon {
  border-radius: 20px;
  border: 1px solid $black100;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.finPlaningtabs .nav-tabs .nav-item .nav-link {
  font-size: 18px;
  padding: 7px 14px;
}
.accounttable .table > :not(caption) > * > * {
  border-bottom-width: 0px;
}
hr.my-1 {
  margin: 1rem 0;
  color: #bcbcbc;
  border: 0;
  border-top: none;
  opacity: 1;
}

.loader-img {
  width: auto !important;
}
i.bi-trash {
  &:hover,
  &:focus {
    color: $red100;
  }
}
.dropdown:hover .dropdown-menu {
  display: block !important;
}

.redirect-link {
  font-size: 18px;
}

.onboarding-main-tabs > .nav-tabs {
  .nav-item {
    width: 50%;
    padding: 0 10px;
    .nav-link {
      border: 1px solid $grey300;
      border-radius: 4px;
      background: $grey15;
      color: $black100;
      width: 100%;
      text-align: left;
      .tab-icon {
        border: 1px solid $grey75;
        border-radius: 4px;
        width: 35px;
        height: 35px;
        i {
          font-size: 16px;
        }
      }
      p {
        font-size: 12px;
      }
      &.active {
        border: 1px solid var(--primary100);
        background: var(--primary15);
        color: $black100;
        .tab-icon {
          border: 1px solid var(--primary100);
          color: var(--primary100);
        }
        .badge {
          background: var(--primary100) !important;
        }
      }
      &.completed {
        border: 1px solid var(--primary50);
        background: var(--primary15);
        color: $black100;
        .tab-icon {
          border: 1px solid var(--primary100);
          color: var(--primary100);
        }
        .badge {
          background: $green100 !important;
        }
      }
    }
  }
}
.empSearch {
  .input-group {
    i {
      position: static;
      color: $white100;
      font-size: 14px;
    }
    .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
.pending-request-list {
  .badge {
    min-width: 25px;
    height: 18px;
    font-size: 14px;
    padding: 2px;
    box-shadow: 0 0 5px #ddd;
    box-shadow: 0px 0px 5px 0px #00000026;
  }
  p {
    font-size: 13px;
    line-height: 15px;
  }
}
.etl-wrapper {
  .netDetilas {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 16px;
    }
  }
  a {
    text-decoration: none;
  }
}
.border-right {
  border-right: #ddd solid 1px;
}
.text-path {
  h3 {
    font-size: 12px !important;
  }
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}
svg > g > text {
  display: none;
}
#trends-tabs-tabpane-aum svg > g > text {
  display: block;
}

.cardOptions {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 1;
  .form-check-label {
    padding-top: 5px;
  }
}

.blue-gradient-box,
.green-gradient-box {
  box-shadow: 0px 2px 4px 0px #00000033;
  padding: 7px 20px;
  border-radius: 10px;

}
.dashboardWrapper {
.blue-gradient-box {
  background: linear-gradient(180deg, #5694f1 0%, #393b88 100%);
  h4{
    color: #ffffff!important;
  }
}
}
.dashboardWrapper {
.green-gradient-box {
  background: linear-gradient(180deg, #18d26b 0%, #116637 100%);
  h4{
    color: #ffffff!important;
  }
}
}

.rounded-10 {
  border-radius: 10px !important;
}

.text-green {
  color: #13a455;
}
.text-green {
  color: #13a455;
}

.bg-pink {
  background: #ff78a8;
}

.shadowBox {
  box-shadow: 1px 2px 4px 0px #00000033;
  border-radius: 6px;
  p {
    line-height: 16px;
    font-size: 12px;
  }
}

.transactionsToday {
  .shadowBox {
    min-height: 76px;
  }
}

.upload-stock-wrapper {
  max-width: 400px;
  .bottom-notes {
    p {
      font-size: 12px;
    }
  }
}

.profilePhotoBox {
  .small {
    line-height: 14px;
  }
  .profilePhotoEdit {
    position: absolute;
    bottom: 0;
    right: 50px;
    z-index: 9;
    .file-upload {
      width: 30px;
      border-radius: 50px;
      background: var(--primary50);
      color: $white100;
      border: 0;
      p {
        display: none;
        color: $white100;
      }
      &:after {
        content: "\f220" !important;
        color: $white100;
        width: 20px;
        right: 3px;
        top: 0px;
      }
      .form-control {
        width: 30px;
        padding: 5px !important;
      }
    }
  }
}

.aws-monitoring-wrapper {
  .netDetilas {
    color: $black100;
    h5 {
      font-size: 18px !important;
      min-height: 22px;
    }
    i {
      font-size: 22px;
    }
    .small {
      font-size: 10px;
      line-height: 15px;
    }
  }
  .ec-box {
    height: 140px;
    overflow: hidden;
  }
  .costs-box {
    min-height: 250px;
  }
}
.profileViewCard {
  .tab-content {
    min-height: 250px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.stats-dashboard {
  .cursor-pointer {
    &:hover {
      color: var(--primary200);
      text-decoration: underline;
    }
  }
}

.reportsListContainer {
  .featurebox {
    margin: 0;
    height: 100%;

    .perform-overlay {
      background: var(--primary50);
      opacity: 0.95;
      border-radius: 3px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: 0.5s ease;
      padding: 0 8px;
      p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 0;
        opacity: 1;
        text-shadow: 0 0 4px var(--primary50);
      }
    }
    &:hover {
      .perform-overlay {
        padding: 8px;
      }
    }
  }
}
.border-primary{
  border-color: var(--primary100)!important;
}

.helpdesk-section {
  p {
    font-size: 12px;
    line-height: 16px;
  }
}

.ria-ceo-aua {
  .inv-card {
    .px-4.py-3 {
      padding: 0 !important;
      h3 {
        margin-top: .5rem!important;
        margin-bottom: 0 !important;
        font-size: 15px !important;
      }
    }
  }
}

.inv-card {
  .goal-list {
    overflow-x: hidden;
    .goal-status {
      &:last-of-type {
        border-right: 0 !important;
      }
    }
  }
}

.topRole {
  .form-select {
    border: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
   // color: $black100 !important;
    &:hover,
    &:focus {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}
.hide{
  display: none;
}

.text-propercase {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.helpdesk-section {
  .cursor-pointer {
    &:hover {
      color: var(--primary100);
    }
  }
}

.announcement-arrow {
  .bi {
    font-size: 18px;
    transition: transform 0.3s ease;
  }
  .bi[aria-expanded="true"] {
    transform: rotate(270deg);
    transition: transform 0.3s ease; /* Smooth rotation */
  }
}

.tdmWrapper {
  .form-label {
    margin-bottom: 0 !important;
  }
  .btn.btn-sm.btnIcon .bi {
    font-size: 16px !important;
    line-height: normal !important;
  }
}

.social-media-lbl {
  min-width: 70px;
}

.profileBlock {
  font-size: 12px;
}

.nowrap {
  white-space: nowrap;
}

.actionIcons {
  li {
    min-width: 24px;
  }
}

.cdPreview {
  position: absolute;
  right: 0px;
  top: -5px;
  z-index: 9;
}

.poStats {
  p {
    font-size: 12px;
  }
  h4 {
    font-size: 15px !important;
  }
}

.certificateUpload,
.logoUpload {
  .file-upload {
    width: 100%;
  }
}