@import "./variables.scss";
@import "./mixins.scss";

h1,
.login-wrapper h1,
// .page-wrapper .container h1, 
.container-xl h1,
// .page-wrapper .container .pageHeading, 
.container-xl .pageHeading {
    font-size: 16px !important;
    color: $black100;
}
.container-xl .formCard{
    h1{
        line-height: 30px;
        margin: 0px;
        align-items: center!important;
    }
}

h2.pageTitle{
    border: none !important;
    padding-left: 120px !important;
    padding-left: 0px !important;
    margin-top: 5px;
    .filters{
        font-size: 14px;
        float: right;
    }
}

h3.subtitle{
    margin-top: 15px;
    small {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .divRight{
        text-align: right;
        float: right;
        background-color: $white100;
        padding-left: 10px;
    }
}
h2 {
    font-size: 15px !important;
}
h3.dashbordHeadings,
h3 {
    font-size: 14px !important;
}
h4 {
    font-size: 15px !important;
}
h5 {
    font-size: 14px !important;
}
h6 {
    font-size: 14px !important;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
p {
    font-size: 15px;
}
.subtitle-noborder{
    float: left;
    font-size: 1.20rem!important;
    width: 100%;
    font-weight: 500;   
}
small,
.small {
    font-size: 11px !important;
}
.filterLink{
    margin-left: auto;
    font-weight: normal;
    font-size: 14px;
}
.text-grey{
    color: #999 !important;
}
.text-warning{
    color: #ff8521;
}
.text-error{
    color: #ff0000;
}
.topRole{
    .form-select{
       // background-color: #212020 !important;
        color: var(--primary50) !important;
    }
}
.wordWrap{
    word-wrap: break-word!important;
}
.text-bold{
    font-weight: bold!important;

}