@import "./variables.scss";
@import "./mixins.scss";

.sidebar-menu > li > a i,
.skin-blue .sidebar-menu .treeview-menu > li > a i {
  margin-right: 12px;
  font-size: 18px;
  &.bi-chevron-left {
    position: relative;
    top: -5px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .main-header .logo {
    line-height: 26px;
    font-size: 14px;
  }
  .mainTopHeader {
    padding: 5px 10px !important;
  }
  .sidebar-open .content-wrapper,
  .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
    -o-transform: translate(230px, 0);
    transform: translate(230px, 0);
  }
}
.main-header .sidebar-toggle {
  float: left;
  background-color: $transparent;
  background-image: none;
  padding: 0px 15px;
  font-family: $font-family-bootstrap-icons;
  // font-size: 2rem;
  display: none;
}
.main-header .sidebar-toggle:before {
  color: var(--primary100);
  font-size: 26px;
  content: '\f479';
}
.main-header .sidebar-toggle:hover {
  color: $white100;
}
.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
  background: $transparent;
}
.main-header .sidebar-toggle .icon-bar {
  display: none;
}
.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding-top: 51px;
  min-height: 100%;
  width: 230px;
  z-index: 9998;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .main-sidebar {
    padding-top: 65px;
  }
  .mainTopHeader .sidebar-toggle {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.sidebar {
  padding-bottom: 10px;
}
.sidebar-form input:focus {
  border-color: $transparent;
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li > a {
  padding: 7px 5px 7px 10px;
  display: block;
  font-size: 14px;
}
.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}
.sidebar-menu > li .badge {
  margin-top: 3px;
}
.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}
.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}
.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.sidebar-menu .menu-open > a > .fa-angle-left,
.sidebar-menu .menu-open > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidebar-menu .active > .treeview-menu {
  display: block;
}
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px !important;
    z-index: 850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -12px;
    margin-right: -15px;
    font-size: 18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > span:not(.pull-right),
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu {
  display: block !important;
  position: absolute;
  width: 180px;
  left: 50px;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > span {
  top: 0;
  margin-left: -3px;
  padding: 12px 5px 12px 20px;
  background-color: inherit;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > .pull-right-container {
  position: relative !important;
  float: right;
  width: auto !important;
  left: 180px !important;
  top: -22px !important;
  z-index: 900;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > a
  > .pull-right-container
  > .label:not(:first-of-type) {
  display: none;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
  .sidebar-menu
  > li:hover
  > .treeview-menu {
  top: 44px;
  margin-left: 0;
}
.sidebar-expanded-on-hover .main-footer,
.sidebar-expanded-on-hover .content-wrapper {
  margin-left: 50px;
}
.sidebar-expanded-on-hover .main-sidebar {
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.125);
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-menu:hover {
  overflow: visible;
}
.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}
.sidebar-menu li > a {
  position: relative;
}
.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -12px;
}
