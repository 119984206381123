@mixin gradientButton($background, $boder, $color){
    height: 40px;
    border: 1px solid transparent;
    color: $color;
    background-color: $background;
    border: $boder;
}
// @mixin title(){
//     font-family: 'Roboto';
//     font-weight: 500;
//     font-size: 18px;
//     color: #3A454F;
// }
@mixin heading-scale($heading-fontsize-scale-5,$heading-color-scale-5,$font-weight-400) {
    font-size: $heading-fontsize-scale-5;
    color:$heading-color-scale-5;
    font-weight:$font-weight-400;
}

@mixin label (){
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

@mixin allInput($color){
    background:$color;
    border: 1px solid #D4D8DB;
    border-radius: 4px;
}

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

