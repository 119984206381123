@import "./variables.scss";
@import "./mixins.scss";

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  // login page
  .signin-container{
    width: 360px;
  }
  .login-wrapper{
    min-height: auto;
  }
  // goal page
  .goalpage{
    .border-left{ 
      border-left: 0 !important;
    }
    .lastcolbtn{
      height: 60px;
    }
  }
  .formBtn{
    margin-top: 10px !important;
  }
  .text-path{
    min-height: auto !important;
    padding: 2px;
  }
  .filter-col{
    display: block !important;
    float: none !important;
    .form-control,.form-select{
      width: 100% !important;
    }
  }


 .main-header {
  .navbar {
    .navbar-collapse {
      background: rgb(255, 255, 255);
    }
  }
}
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

  .main-header {
    position: sticky !important;
    top: 0;
    .navbar {
      .navbar-toggler{
        color: #fff!important;
      }
      //position: absolute;
      //top: 0px;
      .navbar-collapse {
        position: fixed;
        left: 0px;
        width: 100%;
        z-index: 1050;
        background: rgb(255, 255, 255);
        top: 53px;
        a.nav-link{
          padding: 5px;
          color: $black100!important;
        }
      }
    }
  }
  .po-wrapper .content-wrapper {
      margin-left: 0;
  }

  .home {
    .container{
      position:relative;
      padding: 10px;
      z-index: 9999999 ;
    }
    h1{
      color: #fff;
      font-size: 20px!important;
    }
    h3{
      font-size: 14px!important;
    }
  }

  .userprofile-menu {
    .profile-name {
      h6 {
        margin-bottom: 0 !important;
      }
    }
  }

  // ceo dashboard
  .primary-box-gradient{
    margin-bottom: 10px;
  }
  // admin dashboard
  .tab-content{
     .card{
      margin-bottom: 10px;
     }
  }
  // footer
  .main-footer{
    .text-start{
      text-align: center !important;
    }
    .social-links{
      .text-end{
        text-align: center !important;
      }
    }
    ul {
      width: 100%;
      justify-content: center !important;
    }
  }
  // pageWrapper
  .pageWrapper{
    min-height: auto;
  }
  .navbar-toggler{
    // background-color: #fff;
  }


 }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
  .statsCards{
    flex-direction: column-reverse;
  }
  .border-right{
    border: none;
  }
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
  
 }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
  
 }