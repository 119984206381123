@import "./variables.scss";
@import "./mixins.scss";

/* Progressbar CSS */
.progressbar {
    li {
        list-style-type: none;
        width: 14%;
        display: inline-block;
        font-size: 14px;
        position: relative;
        text-align: center;
        color: $grey100;
        z-index: 9;
        &:before {
            width: 25px;
            height: 25px;
            content: "\f4f0";
            font-family: $font-family-bootstrap-icons;
            display: block;
            text-align: center;
            margin: 0 auto 2px;
            background-color: $white100;
            font-size: 25px;
            border-radius: 50%;
            line-height: 30px;
         }
         &:after {
            width: 100%;
            height: 2px;
            content: '';
            position: absolute;
            background-color: $grey100;
            top: 15px;
            left: 55%;
            z-index: -1;
        }
        &:last-child {
            &:after {content: none;}
        }

        &.active, &.completed {
            color: var(--primary50);
            &:before {
                border-color: $grey100;
            }
            span {
                color: var(--primary50);
            }
        }
        &.warning {
            color: $orange100;
            &:after {
                background-color: $orange100;
            }
        }
        span {
            color: $black100;
        }
    }
    li.active + li:after {
        background-color: $grey100;
    }
    li.active + li.completed:after {
        background-color: var(--primary50);
    }
    li.active + li.warning:after {
        background-color: $orange100;
    }
    li.completed {
        &::before {
            content: "\f272";
            background: var(--primary50);
            color: var(--primary50);
            font-size: 14px;
            font-weight: 600;
            color: $white100;
            border-color: var(--primary50);
            line-height: 26px;
            margin-top: 2px;
        }
        &::after {
            background: var(--primary50);
        }
    }
}

/* Top Tabs CSS */
.onboardTabs{
    li{
        width: auto;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        a{
            display: block;
            border-radius: 0 !important;
            padding: 0px 8px;
            border: 1px solid #D9D5EC !important;
            border-left: 1px solid $transparent !important;
            position: relative;
            &:after{
                content: '';
                border-left: 8px solid $white50;
                border-top: 10px solid $transparent;
                border-bottom: 10px solid $transparent;
                position: absolute;
                right: -7px;
                top: 12px;
                z-index: 1;
            }
            &:before{
                content: '';
                border-left: 8px solid #D9D5EC;
                border-top: 10px solid $transparent;
                border-bottom: 10px solid $transparent;
                position: absolute;
                right: -8px;
                top: 12px;
                z-index: 1;
            }
            &.active, &:hover, &:focus {
                border-radius: 0;
                background-color: $white100 !important;
                color: var(--primary100);
                &:after{
                    content: '';
                    border-left: 8px solid $white100;
                    border-top: 10px solid $transparent;
                    border-bottom: 10px solid $transparent;
                    position: absolute;
                    right: -7px;
                    top: 12px;
                    z-index: 1;
                }
                &:before{
                    content: '';
                    border-left: 8px solid #D9D5EC;
                    border-top: 10px solid $transparent;
                    border-bottom: 10px solid $transparent;
                    position: absolute;
                    right: -8px;
                    top: 12px;
                    z-index: 1;
                }
                .tabdetail, i {
                    color: var(--primary100);
                }
            }
            &.completed {
                border-bottom-color: var(--primary50) !important;
                .tabdetail, i {
                    color: var(--primary50);
                }
            }
            &.warning {
                border-bottom-color: $orange100 !important;
                .tabdetail, i {
                    color: $orange100;
                }
            }
        }
        i{
            color: $black50;
            font-size: 30px;
            float: left;
            height: 42px;
            margin: 0 10px 0 7px;
        }
        .tabdetail{
            color: $black50;
            font-size: 15px;
            line-height: normal;
            margin-top: 5px;
        }
        .tabInfo{
            color: $black25;
            font-size: 10px;
            margin-bottom: 3px;
        }
        span{
            display: block;
        }
        &:last-child{
            a{
                &::before, &:after{
                    content: none;
                }
            }
        }
        &:first-child{
            a{
               border-left: 1px solid #D9D5EC !important;
            }
        }
    }
}
// Mobile View
@media only screen and (max-width: 768px) { 
    .onboardTabs{
        li{
            .tabInfo{ 
                display: none;
            }
            .tabdetail{
                margin-bottom: 5px;
                display: inline-block;
                max-width: 60px;
                font-size: 12px;
            }
        }
    }
    .investor-reg-dtls {
        .btn {
            &.btn-sm {
                height: auto !important;
            }
        }
    }
}
.sapAccordion{
    .show{
        background: $white100;
    }
    .accordion-button{
        background: $white50;
        color: $black50;
        .questionHead{
            font-size: 14px;
            color: $black100;
        }
        .weightPro{
            color: $black100;
            font-size: 14px;
        }
        em{
            font-style: normal;
            margin-right: 10px;
        }
        &:after{
            display: none;
        }
        .accordIcon{
            ul{
            margin: 0px;
            li{
                float: left;
                a{
                margin: 0 3px;
                border: 1px solid $black50;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                display: flex;
                text-align: center;
                justify-content: center;
                text-decoration: none;
                .bi{
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    color: $black50;
                }
                &:active, &:hover{
                    border: 1px solid var(--primary100);
                    .bi{
                    color: var(--primary100);
                    }
                }
            }
            }
         }
    }
    }
    .accordion-body{
        textarea{
            height: 92px;
        }
    }
    .questionOption{
        .closeInput{
            .bi{
                cursor: pointer;
                color: $red100;
            }
        input{
            width: 100px;
            float: left;
        }
        }
    }
}

/* Table CSS */
table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: var(--primary50);
}
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
    content: "-";
    background-color: var(--primary100);
}

div.dataTables_wrapper {
    div.dataTables_length {
        label {
            width: 100%;
            text-align: right;
        }
        select,
        select:focus {
            width: 65px;
            display: inline-block;
            padding: 10px 5px !important;
            border: 0 !important;
            background-color: transparent;
            box-shadow: none !important;
            color: $black100;
        }
    }
    .action-icons {
        ul {
            li {
                display: inline-block;
                a {
                    color: $grey300;
                    font-size: 12px;
                    display: inline-block;
                    margin: 0 2px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .btn-sm {
            height: 30px;
            min-width: auto !important;
            font-size: 14px;

        }
    }
}

table.dataTable {
    thead>tr>th.sorting:before,
    thead>tr>th.sorting_asc:before,
    thead>tr>th.sorting:after,
    thead>tr>th.sorting_asc:after {
        opacity: 0.5;
        line-height: 10px;
        font-size: 1.5em;
    }
}
.page-link {
    padding: 0.3rem 0.5rem;
}
.page-item.active {
    .page-link {
        background-color: var(--primary100);
        border-color: var(--primary100);
        &:hover,
        &:focus {
            background-color: #eafffd;
            color: var(--primary100);
        }
    }
}

.dataTables_wrapper,
.dataTables_paginate,
.page-link {
    font-size: 14px;
}

.dt-responsive {
    box-shadow: 0px 2px 5px rgba(155, 155, 155, 0.2);
    border-radius: 8px;
}

.dataTables_wrapper {
    position: relative;
    .table-header {
        position: absolute;
        bottom: 2px;
        z-index: 99;
        left: -10px;
    }
    .dataTables_filter {
        display: none;
    }
    .dt-responsive {
        box-shadow: 0px 0px 5px 2px rgb(155 155 155 / 20%);
        border-radius: 8px 8px 0 0;
        margin-bottom: 0 !important;
    }
    .table-footer {
        padding: 5px 8px 3px;
        background: $white50;
        box-shadow: 0px 2px 5px rgb(155 155 155 / 20%);
        border-radius: 0 0 8px 8px;
        position: relative;
        z-index: 9;
    }
    table.dataTable {
        thead>tr>th.sorting {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
    .dataTables_info {
        padding-top: 8px !important;
    }
    .child .btn-sm {
        height: 30px;
    }
}
.paginationDiv{
    background: #f7f7f7;
    display: flex;
    ul{
        margin: 0px;
        padding: 10px;
        margin-left: auto;
    }
}
.reportWrapper {
    div.dataTables_wrapper {
        div.dataTables_length {
            select {
                padding: 5px !important;
            }
        }
    }
    .page-link {
        padding: 4px 8px;
        font-size: 14px;
    }
}

table.dataTable>tbody>tr.child {
    ul.dtr-details {
        width: 100%;
        ul {
            li {
                display: inline-block;
                .btn-more {
                    min-width: 15px !important;
                    padding: 5px 1px;
                }
            }
        }
    }
    span.dtr-title {
        text-transform: uppercase;
        font-size: 12px;
    }
}
table {
    .gradientHeader {
        background: linear-gradient(90deg, var(--primary100) 0%, var(--primary100) 99.99%);
        color: $white100;
    }
    tr {
        th, td {
            vertical-align: middle;
            padding: 3px !important;
            .form-check-input[type=checkbox] {
                width: 14px;
                height: 14px;
            }
        }
        th {
            font-size: 12px;
            outline: none;
        }
        td {
            font-size: 11px;
        }
    }
    .btn.btn-md {
        min-width: 80px !important;
    }
}
.table-report {
    box-shadow:0px 0px 5px rgba(205, 214, 218, 0.6);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    tr {
        th, td {
            vertical-align: middle;
            font-size: 11px !important;
        }
        th {
            padding: 3px;
            font-size: 12px !important;
        }
    }
}

/* Modal CSS */
.modal-open {
    .modal {
        opacity: 1;
        z-index: 99999;
        .modal-header {
            padding: 12px 15px !important;
            border-bottom: 1px solid var(--primary200) !important;
            h4 {
                font-size: 22px;
                color: var(--primary100);
                font-weight: 600;
            }
        }
        .modal-header h2,
        .modal-title {
            //olor: var(--primary100);
            color: #000;
            font-size: 15px;
            font-weight: 600;
        }
        .modal-body {
            &.acknowledgement-popup {
                max-height: 65vh;
                overflow-y: auto;
                text-align: justify;
            }
        }
        .modal-content {
            box-shadow: 4px 4px 50px 16px rgba(0, 0, 0, 0.4);
            border-radius: 10px !important;
            font-size: 14px;
            ul {
                list-style-type: disc;
                padding-left: 20px !important;
                li {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.modal-backdrop {
    z-index: 99998;
}
.closeModal {
    border: none;
    background: none;
    position: absolute;
    right: 10px;
    top: 3px;
    z-index: 1;
    i {
        font-size: 28px;
        color: $grey50 !important;
        &.bi-x-circle-fill::before {
            content: "\f62a";
        }
    }
    &:hover,
    &:focus {
        i {
            color: var(--primary50) !important;
        }
    }
}
.profile-img-path {
    word-wrap: break-word
}
.modal-header .btn-close {
    width: 25px;
    height: 25px;
    padding: 0;
    margin-right: 0;
    color: $white100;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJwSURBVHgBxVhNSxxBEH07G3YhuBBIICiE7Ck5BRICuQU8JSAIXv2F/gAFwZOeBEFBPAgLwgqiIM5FRXFBsJ5bjc0wM129PY4PHjuz0z37tqrr9UcH8RgIvwo/CeeFPWFfnz0Ib4U3wnPhWO/N6ES0/aZcQBxy4ZFwZGlsEcQoLGIamRQwavsICOvWPGMq/gj/4iUlKeA7hvreS+FjWaOqCM0J/ws/4nXAaK2jZHx1K8QsCz/g9eCiNRZOUCOI4VxB+niximKBnMBLX1EQx8wXtIf3quHMfZF5D1nSP9A++Jvz7sYX9Btvh0V34VLG6HwPdKKH7GKaUqsNsJo2hPeoN9S+ts1dhCypGmPquhva2Som174hMCjPKWOZW/zmn7a9MYjy28xp3xAYwUFXlVkqy/cOGtqp3vcDYuhpVhu5paBfsJtgSFSKGOKegn5i6gdWVImaJIohehREM3yHOJSJGiWKIToZZp/JB/rDbqCniiF6GdLRqbieCRT0gNlQHMBWS6jDhIImiEdZNS03IOqag5qL9ZiFWFVpW32qDpddfal1yRHymVRRx5l2akKMQ7H6YtI3zrTxhaHxlkFMlagthHHFtv6KcRjowCUEC2AJNp9x6bvQz9B+bk+Y+76xinbW0mVgFNd44RvjNt4O++7CTxmrgruOz2gXR8JDd1OcOqg0R3tgqg78L4rbIO6PuCUZopntc0gMLeEONYIIVtIsLhsDZmETJf5UddhAUSN93vSY4pjZQSEyIUGESx//Bee61Gg5gzxGxckHEXtgxe1S7IkIT9JGaPDAqgh3pLeg15we/CM9ppvuzKmAYzFqGfIEhg+i0PjVe04AAAAASUVORK5CYII=) no-repeat 0 0;
    border: none;
    border-radius: 0;
    opacity: 1;
    background-size: contain;
}
.modal-title h3 {
    margin-bottom: 0;
    font-size: 15px;
    color: var(--primary50);
    font-weight: 400;
}
.modal-content .modal-body{
    padding: 10px 20px;
    padding-bottom: 0px;
}
.modal-body input[type=radio] {
    accent-color: var(--primary50);
}
.modal-footer{
    padding: 10px 15px !important;
    border: 0 !important;
}

@media (max-width: 767px) {
    .panAllModal {
        .modal-content {
            width: 100%;
            left: 0;
        }
    }
    .onboardTabs{
        li{
            a {
                height: 100%;
            }
        }
    }
    .progressbar {
        display: flex;
        li {
            flex-basis: 100%;
        }
    }
}

.filter-section {
    .btn-light {
        background-color: $transparent !important;
        border-radius: 4px 4px 0 0 !important;
        border: 0 !important;
        box-shadow: none !important;
        font-size: 18px !important;
        position: relative;
        z-index: 1; 
        &:focus,
        &:hover,
        &:active {
            background-color: $transparent !important;
            box-shadow: none !important;
            border: 0 !important;
        }
        .filter-toggle-icon {
            width: 20px;
            height: 20px;
            background: #68A557;
            border-radius: 50%;
            color: #fff;
            font-size: 14px;
            line-height: 21px;
        }
        &.collapsed {
            .bi-chevron-up::before {
                content: "\f282";
            }
        }
    }
    h4 {
        font-size: 14px !important;
        .bi {
            font-size: 12px;
        }
    }
    .card {
        border-radius: 0 0 4px 4px;
        border: 0 !important;
        box-shadow: none !important;
    }
}

.alert {
    padding: 5px 20px !important;
    border: 0 !important;
    margin-bottom: 10px !important;
    &.alert-danger {
        background: rgba(240, 61, 62, 0.11);
        color: $red100;
    }
    &.alert-success {
        background: rgba(123, 194, 104, 0.28);
        color: var(--primary50);
    }
    &.alert-dismissible {
        i {
            font-size: 17px;
            margin-right: 5px;
        }
        .btn-close {
            padding: 10px 15px;
        }
    }
}

.table-pagination {
    .paginate_button {
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 400 !important;
        color: #6c757d;
        border-color: #a8aeb5 !important;
        text-transform: none;
        &.previous {
            border-radius: 4px 0 0 4px !important;
        }
        &.next {
            border-radius: 0 4px 4px 0 !important;
            margin-left: -1px;
        }
    }
}

.tableFixHeadWrapper {
    // border: #cfd3d7 solid 1px !important;
    max-height: 350px;
    border-radius: 10px;
    overflow-y: auto;
    &.table-aum-growth-report {
        max-height: 310px;
    }
    .tableFixHead {
        thead {
            th {
                position: sticky;
                top: -1px;
                z-index: 1;
            }
        }
    }
}

.table-wrapper {
    .table-responsive {
        box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.35);
        border-radius: 8px;
    }
    .custom-table {
        box-shadow: 0px 0px 5px rgb(155 155 155 / 35%);
        border-radius: 8px;
        .radio-btn-col {
            min-width: 25px;
            width: 25px;
        } 
    }
    .table-footer {
        font-size: 12px;
        .table-info {
            span {
                color: var(--primary50);
                font-weight: 700;
                background: $transparent;
            }
        }
        .form-select {
            font-size: 12px !important;
            height: 28px !important;
        }
        .pagination {
            li {
                a {
                    padding: 4px 8px;
                    font-size: 12px;
                    &:hover,
                    &focus {
                        color: var(--primary50);
                    }
                }
            }
        }
    }
}
div.dataTables_wrapper div.dataTables_paginate {
    margin-left: 20px;
}
.modal-body {
    color: $black100;
    .init {
        i {
            font-size: 36px;
            &.success{
                color:var(--primary100);
            }
        }
    }
    textarea.form-control {
        resize: none;
        height: 90px !important;
    }
}
.modal-backdrop.show {
    z-index: 9999;
}

.async-select {
    .css-1rsipba-control,
    .css-19lppr1-control {
        height: auto !important;
        max-height: auto !important;
    }
    .react-select__control,
    .css-13cymwt-control,
    .css-1tk2o0n-control,
    .css-1m4kt2t-control,
    .css-1jlgqgn-control,
    .css-14yfkki-control,
    .css-1mud9ss-control,
    .css-1jr12un-control {
        border: 1px solid #ced4da !important;
        background: $white100;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        height: 30px !important;
        min-height: 30px !important;
        &.css-1rsipba-control {
            height: auto !important;
            max-height: auto !important;
        }
        .react-select__value-container,
        .css-1fdsijx-ValueContainer {
            padding: 0 8px !important;
            .react-select__input-container,
            .css-qbdosj-Input {
                margin: 0 !important;
            }
        }
        &:focus {
            .react-select__value-container,
            .css-1fdsijx-ValueContainer {
                padding: 0 8px !important;
                .react-select__input-container,
                .css-qbdosj-Input {
                    margin: 0 !important;
                }
            }
            .react-select__indicators,
            .react-select__indicator,
            .css-1xc3v61-indicatorContainer,
            .css-1hb7zxy-IndicatorsContainer,
            .css-151sz6c-IndicatorContainer {
                padding: 2px 5px !important;
            }
        }
        .react-select__indicators,        
        .react-select__indicator,
        .css-1xc3v61-indicatorContainer,
        .css-1hb7zxy-IndicatorsContainer,
        .css-151sz6c-IndicatorContainer {
            padding: 2px 5px !important;
        }
        .react-select__single-value {
            margin-left: 0;
            margin-right: 0;
            padding: 5px 0;
        }
    }
    .react-select__menu,
    #react-select-3-listbox,
    #react-select-5-listbox {
        margin: 0 auto !important;
        z-index: 9999 !important;
        font-size: 14px !important;
        display: block !important;
        .react-select__menu-list {
            max-height: 230px !important;
            z-index: 9999 !important;
            display: block !important;
        }
    }
    .react-select__placeholder,
    #react-select-3-placeholder,
    #react-select-5-placeholder {
        font-size: 12px;
        font-weight: 400;
        color: #9AA3A1;
    }
    .react-select__input-container,
    .css-qbdosj-Input {
        margin: 0 !important;
    }
}
.custom-tooltip {
    border: 1px solid $black100;
    border-radius: 3px;
    font-size: 10px;
    padding: 5px 8px;
    background: $white100;
    p {
        font-size: 10px;
    }
}

.tableFooter{
    border-top: 0;
    margin-top: 5px;
    font-size: 12px;
    .table-info {
        margin: 3px 0;
    }
    ul.pagination {
        .page-item {
            .page-link {
                font-size: 12px;
                font-weight: 500;
                padding: 5px 10px;
                &:hover,
                &:focus {
                    color: $green100;
                }
            }
        }
    }
}

.nav-pills {
    .nav-link.active, 
    .show>.nav-link {
        background-color: var(--primary50);
        i {
            &::before {
                color: $white100;
            }
        }
    }
    .nav-link {
        color: $black100;
        font-size: 12px;
        i {
            vertical-align: text-bottom;
        }
    }
}

.tab-bg-custom {
    background-color: #d5edc7;
}

.table-footer {
    background: $transparent !important;
    margin-top: 5px !important;
}

.editorWrapper .rdw-editor-toolbar,
.rdw-inline-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper,
.rdw-link-wrapper,
.rdw-image-wrapper,
.rdw-history-wrapper {
    display: flex;
    flex-wrap: wrap;
}

// Custom Scrollbar
::-webkit-scrollbar{
    width:8px;
    height: 8px;
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #ddd;
    box-shadow: inset 0 0 6px rgba(0,0,0,.5);
}
::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: $primary05;
    box-shadow: inset 0 0 6px rgba(0,0,0,.4);
}

.MuiPaper-root {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.45) !important;
    border: 1px solid $primary05;
    border-radius: 4px;
    overflow: hidden;
    margin-top: -40px !important;
    margin-right: -16px !important;
    .MenuHeight {
        border-bottom: 1px solid #dee2e6;
        padding: 3px 8px !important;
        display: flex;
        align-items: center;
        font-size: 12px;
        &:hover {
            background: var(--primary50);
            cursor: pointer;
            .dropdownMenuFS {
                font-size: 12px !important;
                margin-left: 6px;
                color: $white100;
            }
            i {
                &::before {
                    color: $white100;
                }
            }
        }
        .dropdownMenuFS {
            font-size: 12px !important;
            margin-left: 6px;
            color: $black100;
        }
        i {
            padding-right: 0 !important;
        }
    }
}

// Accordion CSS
.accordion {
    .accordion-item {
        //margin-bottom: 2px;
        .accordion-button {
            //background-color: var(--primary25) !important;
            font-size: 14px;
            font-weight: 400;
            padding: 12px 15px;
            color: $black100;
            line-height: normal;
            &:focus {
                box-shadow: none;
            }
            &:after {
                transform: rotate(270deg);
            }
            &:not(.collapsed)::after {
                transform: rotate(360deg);
            }
        }
        .accordion-dp {
            width: 120px;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
}

.popover {
    .popover-header {
        background-color: var(--primary25) !important;
    }
}

/*New Theme styles*/
.dashboardContainer{
    background-color: $white100;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.1);
    margin-top: 10px;
    border-radius: 10px;
    padding: 15px;
}
.formCard{
    background-color: $white100;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.1);
    margin-top: 10px;
    border-radius: 10px;
    padding: 15px;
    .formCard{
        padding: 0px;
        box-shadow: none;
    }
    .formCardIcn{
        text-align: center;
        margin: 0 auto;
        background: var(--primary100);
        padding:0px 5px;
        border-radius: 5px;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.1);
        color: $white100;
        width: 40px;
        font-size: 25px;
        margin-top: -25px;
        margin-left: 0px;
    }
    h2{
        // text-align: left;
        // font-weight: 300;
        // margin-bottom: 10px;
        // border-bottom: #ddd solid 1px;
        .downloadLink{
            margin-left: auto;
            font-size: 14px;
        }
        &.accordion-header {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
    .downloadLink{
        margin-left: auto;
        font-size: 14px;
        text-align: right;
    }
}
.statsCards{
    text-align: center;
    font-size: 14px;
    h4{
        font-size: 20px!important;
        padding: 0px;
    }
    .col{
        padding: 5px;
    }
    .formCard{
        padding:15px 5px;
    }
}

@media (max-width: 768px) { 
    .statsCards{
      flex-direction: column-reverse;
      border-top: #ddd solid 1px;
      padding-top: 15px;
      margin: 10px 0px;
    }
   .row .border-right{
      border: none !important;
    }
   }
.notification.info{
    border: #d0e9f7 solid 1px;
    border-radius: 5px;
    padding: 5px;
    font-style: italic;
    color: #666;
    background: #eef9ff;
    margin: 5px 10px;
    width: -webkit-fill-available;
    .bi{margin-right: 5px;
    color: var(--primary100);}
}

.cardTitle {
    height: 45px;
}

.carousel-indicators [data-bs-target] {
    background-color: $white100;
    bottom: -7px;
}

.chartjs-legend li span {
    width: 15px;  /* Adjust the width */
    height: 15px; /* Adjust the height to keep it a circle */
    border-radius: 50%; /* Make it a circle */
    display: inline-block;
    margin-right: 5px;
  }

  .profilePhotoBox {
    .position-relative {
        max-width: 175px;
        margin: auto;
    }
  }
.profile-photo {
    width: 150px;
    height: 150px;
    border: 2px solid #ddd;
    border-radius: 50%;
    padding: 5px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.tab-pane {

    .formCard{
        padding: 5px!important;
    }
}

  /* Button Group Switch
   ========================================================================== */
 .switch-button{
    height: 24px;
 }
.switch {
  display: inline-block;
  margin-bottom: 0px!important;
  margin-top: 2px!important;
}
 
.switch-button {
  /* background and border when in "off" state */
  background: var(--primary50);
  border: 2px solid var(--primary50);
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 8px;
  color: #FFFFFF;
  position: relative;
  cursor: pointer;
  outline: 0;
  user-select: none;
}
 
.switch-input:not(:checked) + .switch-button .switch-button-left {
  /* text color when in "off" state */
  color: var(--primary50);
}
 
.switch-input {
  display: none;
}
 
.switch-button span {
  font-size: 13px;
  padding: 3px 7px;
  text-align: center;
  z-index: 2;
  color: #FFFFFF;
  transition: color .2s;
}
 
.switch-button::before {
  content: '';
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0,0,0,.4);
  border-radius: 7px;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: 1;
  transition: left .3s cubic-bezier(0.175, 0.885, 0.320, 1),
              padding .2s ease, margin .2s ease;
}
 
.switch-button:hover::before {
  will-change: padding;
}
 
.switch-button:active::before {
  padding-right: .4rem;
}
 
.switchModeBtn{
    .switch-button {
        span{
            padding: 0px 7px!important;
            font-size: 15px;
        }
    background: #212020;
    border: 2px solid #212020;
    }
    .switch-input:not(:checked) + .switch-button .switch-button-left {
        /* text color when in "off" state */
        color: #212020;
      }

      .switch-input:checked + .switch-button .switch-button-right  {
        /* text color when in "off" state */
        color: #212020;
      }
      .switch-input:checked + .switch-button {
        background: #212020;
        border: 2px solid #7e7a7a;
      }
}
 
/* "On" state
========================== */
 
.switch-input:checked + .switch-button {
  /* background and border when in "on" state */
  background-color: var(--primary50);
  border-color:var(--primary50);
}
 
.switch-input:checked + .switch-button .switch-button-right {
  /* text color when in "on" state */
  color: var(--primary50);
}
 
.switch-input:checked + .switch-button::before {
  left: 50%;
}
 
.switch-input:checked + .switch-button:active::before {
  margin-left: -.4rem;
}
 
/* Checkbox in disabled state
========================== */
 
.switch-input[type="checkbox"]:disabled + .switch-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.canvasjs-chart-credit {
    display: none !important;
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
.badge-primary {
    color: #fff;
    background-color: #007bff;
}

.input-group {
    //padding-top: 5px;

.btn {
    position: relative;
    z-index: 2;
    background: #d1d1d1;
    border-radius: 0px !important;
    min-width: 40px!important;
    border-radius: 0px;
    border-top-right-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
    i{
        color: #000 !important;
        margin-top: 3px;
    }
    
}
}
.btn.btn-filter {
   // background: var(--primary100)!important;
    border-radius: 5px!important;
   // color: $white100 !important;

    background: transparent!important;
    border: var(--primary100) solid 1.5px;
    color: var(--primary100) !important;
    padding: 0px 6px!important;
    min-width: auto!important;
    i{
        font-size: 20px;
    } 
    .bi-sliders2::before {
        content: "\F3CA"!important;
    }
}

.btn.btn-filter.collapsed {
    background: transparent!important;
    border: var(--primary100) solid 1.5px;
    color: var(--primary100) !important;
}

.d-flex.align-items-center.justify-content-between 
{
 
    h1{
        margin: 0px!important;
    }
    .btn{
        margin-bottom: 0px!important;
    }
}
.recommendationsCard{
    .multi-collapse.mt-2{
        margin-top: 0px!important;
    }
    .multi-collapse.border-top{
        border-top:#707275 solid 1px!important;
    }
    h1{
        font-size: 14px !important;
        color: var(--primary50)!important;
    font-weight: 600 !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    }
}
.hideElements{
    h1{
        display: none!important;
    }
   
}
.hideElements.tab-pane{
    .tabView{
    display: none!important;       
    }
}

.btn.btn-sm.btnIcon{
    padding: 0px!important;
    border: none!important;
    height: auto!important;
    min-width: auto!important;
    .bi{
        font-size: 26px!important;
        font-weight: 400!important;
        line-height: 25px!important;
    }
}

.btn.btn-sm.btnIcon:hover{
    padding: 0px!important;
    border: none!important;
    height: auto!important;
    min-width: auto!important;
    background: $transparent!important;
    .bi{
        font-size: 26px!important;
        color: var(--primary200) !important;
        font-weight: 400!important;
        line-height: 25px!important;
    }
}

.tooltip{
    padding: 0px !important;
    font-size: 12px !important;
    z-index: 99999 !important;
}
.theme{
        width: 80px;
        height: 30px;
        margin: 5px 0;
}
.default{
    .theme100{
        background: rgba(0, 103, 154, 0.9);
    }
    .theme200{
        background: rgba(0, 103, 154, 1);
    }
    .theme50{
        background: rgba(0, 103, 154, 0.5);
    }
    .theme25{
        background: rgba(0, 103, 154, 0.25);
    }
}
.maroon{
    .theme100{
        background: $maroon100;
    }
    .theme200{
        background: $maroon200;
    }
    .theme50{
        background: $maroon50;
    }
    .theme25{
        background: $maroon25;
    }
}
.red{
    .theme100{
        background: $red100;
    }
    .theme200{
        background: $red200;
    }
    .theme50{
        background: $red50;
    }
    .theme25{
        background: $red25;
    }
}

.orange{
    .theme100{
        background: $orange100;
    }
    .theme200{
        background: $orange200;
    }
    .theme50{
        background: $orange50;
    }
    .theme25{
        background: $orange25;
    }
}

.yellow{
    .theme100{
        background: $yellow100;
    }
    .theme200{
        background: $yellow200;
    }
    .theme50{
        background: $yellow50;
    }
    .theme25{
        background: $yellow25;
    }
}
.green{
    .theme100{
        background: $green100;
    }
    .theme200{
        background: $green200;
    }
    .theme50{
        background: $green50;
    }
    .theme25{
        background: $green25;
    }
}
.blue{
    .theme100{
        background: $blue100;
    }
    .theme200{
        background: $blue200;
    }
    .theme50{
        background: $blue50;
    }
    .theme25{
        background: $blue25;
    }
}
.modeToggle{
    padding: 5px 20px;
    .switch-button{
        overflow: hidden;
    }
    .switch-button span{
        padding: 0px 7px;
    }
    .switch-input:not(:checked) + .switch-button .switch-button-left {
    // .switch-input:checked + .switch-button .switch-button-left {
        color: #ffffff;
        border-radius: 8px;
        background: #000000;
    }
    // .switch-input:checked + .switch-button .switch-button-right {
    //    // color: var(--primary100);
    //     //background: #ffffff;
    // }
}

.input-group .btn{
    background: var(--primary100)!important;
    i{
        color: #fff !important;
    }
}

.apexcharts-legend {
    justify-content: center !important;
    top: 0 !important;
  }
.modal-open .modal .modal-content ul.nav-tabs{

    list-style-type:none;
    padding-left: 0px !important;
    li{
        margin: 0px!important;
    }
} 

.imgBox{
    border-radius: 15px;
    border: #fff 5px solid;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.profilePhotoBox{
    .statusCircle{
        position: absolute;
    }
}
.statusCircle{
    margin: 0 auto;
    //position: absolute;
    right: 20px;
    width: 15px;
    height: 15px; 
   // border: #ffffff solid 2px;
    border-radius: 50%; 
  }
  .statusCircle.active{
    background: rgb(0, 162, 0);
  }
  .statusCircle.inactive{
    background: rgb(193, 193, 193);
  }
  .headerDownloadLinks{
    display: flex!important;
    align-items: center!important;
  }
 
  table.table td .statusCircle.active, 
  table.table td .statusCircle.inactive {
    display: inline-block !important;
    margin-right: 7px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  .profileViewCard .hideElements{
    .btnIcon, h2{
        display: none;
    }

  }     

  .send-email-btn{
    i{
        display: none!important;
    }
    width:23px;
    height: 23px;
    display:block;
    background: url(../Images/icon-email-4.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .fc-tabs {
    display: inline-flex;
    border-radius: 8px;
    border-color: #dee2e6 !important;
    .nav-item {
        .nav-link {
            padding: 3px 15px;
            min-width: 85px;
            text-align: center;
        }
    }
  }

  .react-select__menu-portal {
    z-index: 2 !important;
  }

  .radialBarChart {
    width: 200px;
    margin: auto;
  }

  .returnCompChart {
    .apexcharts-legend-text {
        padding-right: 15px !important;
    }
  }