@import "/src/Assets/Styles/variables.scss";
@import "/src/Assets/Styles/mixins.scss";

.contact-info-section {
  position: relative;
  top: -40px;
  z-index: 9;
  .container {
    i {
      font-size: 24px;
      color: var(--primary100);
    }
    a {
      text-decoration: none;
    }
  }
}
.main-footer {
    background: $grey75 !important;
    color: $white100;
    font-size: 12px;
    padding: 0 10px;
    h4 {
      font-size: 15px !important;
      font-weight: 500;
      border-bottom: 1px solid var(--primary100);
    }
    ul {
      list-style-type: none;
      li {
        a {
          font-size: 12px;
          text-decoration: none;
          color: $black100;
          &:hover{
            text-decoration: none;
            color: var(--primary200);
            i{
              &::before{  
                color: var(--primary50);
              }
            }
          }
          i{
            &::before{ 
              cursor: pointer;
              font-size: 18px; 
              margin-right: 5px;  
              color: $white100;
          }
        }
      }
    }
  }
  .footer-bottom {
    font-size: 12px;
    a {
      color: $white100;
      &:hover,
      &:focus {
        color: var(--primary50);
      }
    }
    .border-top {
      border-color: $white100;
    }
  }
}