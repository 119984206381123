@import "./variables.scss";
@import "./mixins.scss";

.darkMode,
.darkMode.modal, .darkMode .modal {

  .pageWrapper{
    background: #484848 !important;;
  }
  color: #e2e2e2 !important;
  background-color: #3f3f40 !important;
  .statsDiv {
    background-color: #3f3f40 !important;
    color: #e2e2e2 !important;
    border: #7c7c7c solid 1px !important;
  }
  .header {
    border: none !important;
    border-bottom: #2f2d2d solid 1px !important;
  }
  .header,
  .main-footer,
  .page-item .page-link,
  .home-away-switch.mqa-switch .btn {
    background: #191818!important;
    //background-color: #000000 !important;
    color: #e2e2e2 !important;
  }
  .page-item.active .page-link {
    background: var(--primary100) !important;
  }
  .page-item.disabled .page-link {
    color: #7c7c7c !important;
  }
  .formCard,
  .bg-white,
  .sapAccordion .show {
    background-color: #212020 !important;
  }
  .pageWrapper {
    .pageWrapper {
      background-color: transparent !important;
    }
  }
  .formCard {
    .formCard {
      background-color: transparent !important;
    }
  }
  h1,
  .container-xl h1,
  label,
  .form-label,
  h2,
  h4,
  .table > :not(caption) > * > *,
  .table-responsive i,
  .tableContainer i,
  .dashboardWrapper
    .ij-amc-tabs-wrapper
    .dashboardTabs.nav-tabs
    .nav-item
    .nav-link,
  p,
  .tableFooter .table-info,
  .headerRightSec .dropdown-menu li a,
  .dropdown-menu li a .bi,
  .mainTopHeader .dropdown.nav-item .dropdown-menu a,
  .navbar .navbar-nav .nav-link,
  a,
  .retirmentInputsContainer h6,
  .nav-tabs .nav-item .nav-link,
  .bottom-notes,
  .investor-reg-section h3 {
    color: #e2e2e2 !important;
  }
  .table-responsive,
  .tableContainer,
  .table > :not(caption) > * > *,
  .table-footer,
  .commonTable thead th,
  .table thead th,
  .btn.btn-secondary,
  .commonTable thead th.fixedFirstColumn,
  .table thead th.fixedFirstColumn,
  .commonTable thead th.fixedActionColumn,
  .custom-table tr th:first-child,
  .table-report tr th:first-child {
    background-color: #212020 !important;
  }
  .commonTable tr td,
  .table tr td {
    border-right: #7c7c7c solid 1px !important;
  }
  .form-control,  
  .form-select,
  .input-group-text,
  .async-select .react-select__control,
  .file-upload,
  .accordTotal,
  .profileDetailsEdit,
  .profileDetailsArea,
  .card,
  .goalpage .input-group-text,
  .managerinfoSec .card-header,
  .react-select_menu-portal,
  .react-select__menu-list
  {
    background-color: #3f3f40 !important;
    color: #e2e2e2 !important;
    border: #7c7c7c solid 1px !important;
  }
  .react-select__input, 
  
  .async-select .react-select__control .react-select__single-value
  {
    color: #e2e2e2 !important;
  }

  .react-select__option
  {
    background-color: #3f3f40 !important;
    color: #e2e2e2 !important;
    &:hover{
      background-color: #e2e2e2 !important;
      color: #000 !important;
    }
  }

  .form-select {
    option {
      color: #fff;
      background: #3f3f40;
    }
  }
  .PhoneInput .PhoneInputInput {
    background-color: #3f3f40 !important;
    color: #e2e2e2 !important;
  }
  ::placeholder,
  .form-select:has(option[value=""]:checked) {
    color: #acacac;
  }
  .form-select,
  .form-control,
  textarea,
  .input-group-text,
  .navbar-custom-menu .userprofile-menu .dropdown-menu,
  .async-select .react-select__control,
  .file-upload,
  .retirmentInputsContainer .input-group-text {
    border: #7c7c7c solid 1px !important;
  }
  .dropdown-menu {
    border: #7c7c7c solid 1px !important;
  }
  .main-header {
    .logo span,
    .userprofile-menu .profile-name h6 {
      color: #ffffff!important;
    }
  }
  .border-top,
  .navbar-nav .dropdown-menu li {
    border-top-color: #7c7c7c !important;
  }

  .btn-primary {
    border: 1px solid var(--primary100) !important;
    background: var(--primary100);
    color: $white100;
    width: auto;
    &:hover {
      background-color: var(--primary200) !important;
      border-color: var(--primary200) !important;
      color: $white100;
    }
  }

  .btn-secondary {
    background: $white100 !important;
    color: var(--primary50) !important;
    border: 1px solid var(--primary50) !important;
    &:hover {
      background-color: var(--primary200) !important;
      border-color: var(--primary200) !important;
      color: $white100 !important;
      i {
        color: $white100;
      }
    }
  }
  .modal-title {
    color: #ffffff !important;
  }
  .nav-tabs .nav-item .nav-link.active {
    color: var(--primary50) !important;
  }
  .onboarding-main-tabs{
    .nav-tabs .nav-item .nav-link.active {
      p{
        color: #ffffff !important;
      }
      color: #ffffff !important;
      background-color: #777777!important;
      border: #dbdfe2 solid 1px;
      .badge{
        background-color: #ffffff!important;
        color: #000000!important;
      }
    }
  }
  .dashboardTabs.nav-tabs .nav-item .nav-link.active {
    color: #ffffff !important;
  }
  .table-responsive,
  .tableContainer {
    border: #707275 solid 1px !important;
  }
  .border-bottom,
  .commonTable thead th,
  .table thead th,
  .commonTable tr:last-child,
  .table tr:last-child {
    border-bottom: #707275 solid 1px !important;
  }
  .commonTable thead th,
  .table thead th {
    border-right: #707275 solid 1px !important;
  }
  .border-end {
    border-right: #707275 solid 1px !important;
  }
  .dashboardWrapper .dashboardTabs {
    background-color: #000000 !important;
  }
  .dashboardWrapper .nav-tabs .nav-item .nav-link.active {
    background-color: var(--primary200) !important;
  }
  .commonTable tr:nth-child(even) td,
  .table tr:nth-child(even) td {
    background-color: #333435 !important;
  }
  .commonTable tbody tr:hover td,
  .table tbody tr:hover td {
    background-color: #434445 !important;
  }
  .commonTable,
  .Table,
  .table-report {
    tr {
      &.highlighted-row {
        td {
            background-color: #434445 !important;
                color: $white100 !important;
            &:first-child,
            &:first-child:hover {
                background-color: #434445 !important;
                color: $white100 !important;
            }
        }
        &:hover {
            td {
              background-color: #dbdfe2 !important;
              color: $black100 !important;
            }
        }
    }
    &.highlighted-total-row {
        td {
            background-color: var(--primary25) !important;
            color: $black100 !important;
            &:first-child {
                background-color: var(--primary25) !important;
                color: $black100 !important;
            }
        }
        &:hover {
            td {
                background-color: var(--primary25) !important;
                color: $black100 !important;
            }
        }
    }
    &.highlighted-grand-total-row {
      td {
          background-color: var(--primary100) !important;
          color: $white100 !important;
          &:first-child {
              background-color: var(--primary100) !important;
              color: $white100 !important;
          }
      }
      &:hover {
          td {
              background-color: var(--primary100) !important;
              color: $white100 !important;
          }
      }
  }
    }
  }
  .managerinfoSec {
    background-color: transparent !important;
  }
  .headerRightSec .dropdown-menu li a,
  .nav-item .dropdown-menu a {
    background-color: #000000 !important;
    &:hover {
      background-color: #434445 !important;
      .bi {
        color: var (--primary15) !important;
      }
    }
  }
  .retirmentInputsContainer,  .bg-light {
    background-color: #3f3f40 !important;
  }
  .reportsListContainer .card,
  .accordion-item,
  .accordion-button:not(.collapsed),
  .accordion-button
   {
    //background-color: #000000 !important;
    background: #191818!important;
    color: #e2e2e2 !important;
    border: #707275 solid 1px !important;
  }
  .accordion-button:not(.collapsed) {
    color: var(--primary50) !important;
    border: none !important;
    border-bottom: #707275 solid 1px !important;
  }
  a.text-primary,
  .text-primary,
  .navbar .navbar-nav .active .nav-link {
    color: var(--primary50) !important;
  }

  .toolsWrapper {
    .tools-result {
      background-color: #212020 !important;
    }
    .investmentBox {
      background-color: #3f3f40 !important;
    }
  }
  .inv-card, .list-group-item, .bg-box {
    box-shadow: 0px 1px 6px 0px #707275;
    box-shadow: none;
    border: #5b5d5f solid 1px;
    background: #191818!important;
  }
  .allocation-dtls-tabs-wrapper,
  .formCard,
  .apexcharts-canvas {
    svg {
      g {
        text {
          fill: #fff !important;
          text-transform: capitalize;
          color: #fff !important;
        }
      }
    }
    .apexcharts-legend-text {
      color: #fff !important;
    }
  }
  .topRole {
    .form-select {
      background-color: transparent !important;
      border: 0 !important;
    }
  }
  .profileBlock{
    // background-color: rgba(63, 63, 64, 0.7)!important;
    .welcome-txt{
      color: #ffffff !important;
    }
  }

  .tools-result{
    rect{
      fill:transparent!important;
    }
  }
  .po-wrapper{
    a {
        h4, h5 {
            color: var(--primary50) !important;
        }
    }
  }
  .modal-body, .text-muted, .title{
    color: #fff!important;
  }
  .onboarding-main-tabs p{
    color: #ffffff!important;
  }
  .onboarding-main-tabs > .nav-tabs .nav-item .nav-link.completed{

    p{
      color: #ffffff !important;
    }
    color: #ffffff !important;
    background-color: #777777!important;
    border: #dbdfe2 solid 1px;
    .badge{
      background-color:#05b505!important;
      color: #ffffff!important;
    }

 
  }


  .dashboardWrapper .nav-tabs .nav-item .nav-link.active {
    color: #fff!important;
  }

  .onboarding-main-tabs > .nav-tabs .nav-item .nav-link.completed .tab-icon, .onboarding-main-tabs > .nav-tabs .nav-item .nav-link.active .tab-icon{
    border: #ffffff;
    color: var(--primary100);
    background-color: var(--primary15)!important;
  }
  .recommendationsCard h1, .helpdesk-section h4{
    color: var(--primary100)!important;
  }
  .goalpage .active-goal-box{
    background-color:#606060 !important;
    color: #ffffff!important;
    .text-light, i{
      color: #ffffff!important;

    }
  }
  .table-responsive .table > thead > * > *, 
  .commonTable thead th, .table thead th{
    color: var(--primary50)!important;
  }


  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #3f3f40 inset !important;
}
input:-webkit-autofill{
  -webkit-text-fill-color: #e2e2e2 !important;
}
.toolsBanner{
  p{
    color: #000!important;
  }
  color: #000!important;
}
.faq-wrapper{
  p, h5, h6{
    color: #000!important;
  }
  .accordion-button, .accordion-item{
    background-color: #ffffff!important;
    color: #000!important;
    border:none!important;
    p{
      color: #000!important;
    }
  }
  .accordion-button:not(.collapsed){
    background-color: rgba(13,110,223,  0.25)!important;
  }
  .accordion-item{
    border:#e2e2e2 solid 1px!important;
  }
  
  .table-responsive,
  .tableContainer,
  .table > :not(caption) > * > *,
  .table-footer,
  .commonTable thead th,
  .table thead th
  {
    background-color: #ffffff!important;
  }
  .table > :not(caption) > * > *{
    color: #000!important;
  }
  .commonTable tbody tr:hover td, .table tbody tr:hover td{
    background-color: #e2e1e1!important;
  }
}
.dashboardWrapper h4.cardTitle{
  .topRole .form-select{
    color: var(--primary100)!important;
  }
  //color: var(--primary50)!important;
}

.btn.btn-filter {
  color: $white100 !important;
}
// Above code is inside darkMode  
}

.darkMode {
  .CircularProgressbar {
    .CircularProgressbar-text {
      fill: white !important;
    }
  }
  .react-select__input-container {
    .react-select__input {
      color: #fff !important;
    }
  }
  #google-charts-canvas {
    svg {
      g {
        .google-visualization-tooltip > * {
            color: #fff !important;
            fill: #000 !important;
        }
      }
    }
  }
  .shadowBox {
    background-color: #3f3f40;
  }
  .text-dark,
  .text-black {
    color: #fff !important;
  }
  .text-success {
    color: rgb(0 241 129) !important;
  }
  .popover {
    .popover-header {
        color: var(--primary25) !important;
    }
    .popover-header,
    .popover-body {
        background-color: #333435 !important;
    }
  }
  .form-check-input,
  input[type=radio] {
    background-color: #3f3f40;
    border: 1px solid #7c7c7c;
  }
  input[type=radio] {
    outline: 1px solid #7c7c7c;
  }
  .profileViewCard {
    .MuiPaper-root {
      background-color: #333435 !important;
      .MuiTypography-root,
      .MuiSlider-markLabel {
        color: #fff !important;
      }
    }
  }
  #rag-status {
    .bg-warning,
    .bg-danger,
    .bg-success {
      border-bottom: 1px solid #6f7275 !important;
    }
  }
  .pdf-container {
    #google-charts-canvas {
      svg {
        g {
          fill: #fff !important;
          text {
            fill: #fff !important;
          }
        }
      }
    }
  }
  .aws-monitoring-wrapper .netDetilas,
  .main-header .navbar .navbar-nav .nav-item .nav-link:hover,
  .accordion-header .weightPro {
    color: #fff !important;
  }
  .netDetilas {
    border: #6f7275 solid 1px;
  }
}


.darkMode.modal, .darkMode .modal {
  background-color: rgba(63, 63, 64, 0.7) !important;
  .modal-header h2,
  .modal .modal-title {
    color: #ffffff !important;
  }
  .modal-header {
    border-bottom: 1px solid var(--primary200) !important;
  }
  .modal-content {
    background-color: #212020 !important;
    color: #e2e2e2 !important;
    border: #535353 solid 1px !important;
  }
  .pageWrapper{
    background-color: transparent !important;;
  }
}


.darkMode .mfdHome, .darkMode .riaHome, .darkMode .poHome, .darkMode .raHome{
  .bg-white{
    background-color: #ffffff!important;
  }
  .feature-box, .unlocking-potential-section, .count-section, .testimonials-section, .features-section, .solutions-section{
    h2,h4, p{
      color: #3d4648!important;
    }
  }
  .header{
    border: none!important;
  }
  .main-header .logo span,
  .navbar .navbar-nav .nav-link, .darkMode a{
    color: #3d4648!important;
  }
  .book-appointment-box{
    label, .form-label{
      color: #3d4648!important;
    }
    .btn.btn-secondary, .form-control, .input-group-text{
      background-color: #ffffff!important;
      color: #3d4648!important;
    }
    .form-control, .input-group-text{
      border: 1px solid #d4d8db !important;
    }
  }

} 
.darkMode .poHome{
  .rightcontent, p, h2, h4{
    color: #35354b!important;
  }
.slides {
  color: #35354b!important;
  h1{
    color: var(--primary100)!important;
  }
  h4{
    color: #35354b!important;
  }
}
}

.darkMode.popover .popover-header{
  background: #191818!important;
  color: #e2e2e2 !important;
} 