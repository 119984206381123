@import "./variables.scss";
@import "./mixins.scss";

.formgroup {
  margin: 7px 0px;
}
.btn {
  height: 30px !important;
  border-radius: 20px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid $transparent;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  outline: none !important;
  width: auto !important;
  min-width: 60px;
  margin: 0px 5px;
  padding: 4px 10px;
  &.btn-primary {
    border: 1px solid var(--primary100) !important;
    background: var(--primary100);
    color: $white100;
    width: auto;
    &:hover { 
      background-color: var(--primary200) !important;
      border-color: var(--primary200) !important;
      color: $white100;
    }
  }

  &.btn-secondary {
    background: $white100 !important;
    color: var(--primary100) !important;
    border: 1px solid var(--primary100) !important;
    &:hover {
      background-color: var(--primary200) !important;
      border-color: var(--primary200) !important;
      color: $white100 !important;
      i {
        color: $white100;
      }
    }
  }
  &.btn-tertiary {
    background: var(--primary100) !important;
    color: $white100 !important;
    border: 1px solid var(--primary100) !important;
    &:hover {
      background: var(--primary200) !important;
      color: $white100 !important;
      border-color: var(--primary200) !important;
      a {
        color: var(--primary200) !important;
      }
    }
  }
  &.btn-sm {
    min-width: 30px !important;
    height: 30px !important;
    font-size: 12px !important;
    padding: 4px 10px;
  }
  &.btn-md {
    height: 30px !important;
    border-radius: 25px !important;
  }
  &.btn-lg {
    height: 36px !important;
    border-radius: 40px !important;
    padding: 7px 10px;
  }
  &.btn-xlg {
    height: 40px !important;
    border-radius: 45px !important;
    padding: 10px;
  }
  &.btn-light {
    background: $transparent !important;
    color: $white100 !important;
    border: 1px solid $white100 !important;
    &:hover,
    &:focus {
      background: $white100 !important;
      color: $black100 !important;
      border: 1px solid $white100 !important;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
    }
  }
  &.btn-blue {
    border: 1px solid var(--primary100) !important;
    background: var(--primary100);
    color: $white100;
    width: auto;
    &:hover,
    &:active,
    &:focus {
      background-color: var(--primary200) !important;
      border-color: var(--primary200) !important;
      color: $white100;
    }
  }

  &.btn-sm.btnIcon,
  &.btn-sm.btnIcon:hover {
    padding: 0px !important;
    border: none !important;
    height: auto !important;
    // margin: 0px !important;
    min-width: auto !important;
    background: $transparent !important;
    .bi {
      font-size: 26px !important;
      font-weight: 400 !important;
      line-height: 25px !important;
    }
    &.btn-sm.btnIcon {
      &:hover {
        .bi {
          color: var(--primary200) !important;
        }
      }
      &.btn-add {
        .bi {
          font-size: 20px !important;
        }
      }
    }
  }
}

.btn-group .btn {
  margin: 0px;
}

// .btn.btn-rounded {
//    border-radius: 50px !important;
// }
.btn-group {
  .btn {
    &.rounded-start {
      border-radius: 4px 0 0 4px !important;
    }
    &.rounded-end {
      border-radius: 0 4px 4px 0 !important;
    }
  }
}
.form-select:not([data-empty="false"]) {
  color: var(--primary200);
}
.form-select:has(option[value=""]:checked) {
  color: #616d74;
}
.form-select {
  option {
    font-style: normal;
    font-size: 12px;
    color: #212529;
    &:hover,
    &:focus {
      background-color: #ddd;
      color: var(--primary200);
      opacity: 1;
    }
  }
}
.form-select,
.form-control,
textarea {
  font-size: 12px !important;
  color: var(--primary200);
  box-shadow: none;
  padding: 6px 7px !important;
  border: 1px solid #d4d8db !important;
  border-radius: 4px !important;
  height: 30px !important;
  line-height: normal;
}
textarea {
  resize: none !important;
  min-height: 54px !important;
}
.form-select {
  //padding-right: 35px !important;
  padding: 4px 28px 4px 7px !important;
  line-height: normal !important;
}
.form-control:focus,
.form-select:focus,
textarea:focus {
  background: $white100;
  box-shadow: 0 0 0 0.1rem rgb(76 175 80 / 30%) !important;
}
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-control::placeholder {
  color: $grey100 !important;
  opacity: 1;
  text-transform: none !important;
}
.form-control:disabled:-moz-placeholder,
.form-control:disabled:-ms-input-placeholder,
.form-control:disabled::-webkit-input-placeholder,
.form-control:disabled::placeholder {
  color: var(--primary25) !important;
}
.form-control.text-uppercase:-moz-placeholder,
.form-control.text-uppercase:-ms-input-placeholder,
.form-control.text-uppercase::-webkit-input-placeholder,
.form-control.text-uppercase::placeholder {
  text-transform: capitalize !important;
}
.form-control:disabled {
  background-color: $white75 !important;
  color: var(--primary200) !important;
}
.form-check-input {
  width: 20px;
  height: 20px;
  background-color: $white100;
  border: 1px solid #d4d8db;
  border-radius: 3px;
  margin-right: 5px;
  &:checked {
    border-color: var(--primary50);
    background-color: var(--primary50);
  }
}

input[type="date"] {
  text-transform: uppercase;
}
.disabled-input-bg {
  background: #f3f5f6;
}
label,
.form-label {
  color: $black100;
  font-size: 12px;
  margin-bottom: 4px !important;
}
.form-check-label {
  margin-bottom: 2px !important;
}
.formgroup {
  margin-bottom: 10px;
}
.formBtn {
  margin-top: 24px !important;
}
.btn-sm {
  svg {
    height: 20px !important;
  }
}
.btn-add {
  max-height: 26px !important;
  padding: 2px 10px 2px 8px !important;
}
.error {
  font-size: 12px;
  color: $red100;
  line-height: 18px;
  display: block;
  margin-top: 5px;
}
.required {
  .form-label {
    &:after {
      content: "*";
      color: $red100;
      margin-left: 3px;
    }
  }
}
.input-group {
  position: relative;
  .input-icon {
    padding-right: 40px;
  }
  i {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 9;
    top: 40%;
    @include transform(translateY(-50%));
    color: $grey100;
    font-size: 16px;
  }
}
.inputTypeFile {
  font-size: 12px;
  font-weight: 400;
  box-shadow: none;
  padding: 10px;
  border: 1px solid #d4d8db;
  border-radius: 4px;
  height: 40px;
  position: relative;
  &::after {
    content: "upload File";
    position: absolute;
    right: 4px;
    background: #68a557;
    border-radius: 5px;
    width: 108px;
    bottom: 5px;
    height: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    padding: 3px;
  }
  input {
    opacity: 0;
  }
}
.file-upload {
  border: 1px solid #d4d8db;
  background-color: $white100;
  border-radius: 4px;
  position: relative;
  height: 30px;
  line-height: normal;
  input[type="file"] {
    opacity: 0;
    position: relative;
    z-index: 2;
    padding-right: 32px !important;
  }
  &::after {
    content: "\f297";
    font-family: bootstrap-icons !important;
    color: #9aa3a1;
    position: absolute;
    width: 40px;
    height: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    top: 1px;
    right: 0;
    font-size: 16px;
    align-items: center;
    z-index: 1;
    cursor: pointer;
  }
}
.file-path {
  color: var(--primary200);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.file-upload {
  p {
    position: absolute;
    margin: 0;
    color: #9aa3a1;
    width: calc(100% - 40px);
    height: 30px;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.text-path {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
  word-break: break-all;
}
.formWrapper {
  .title {
    small {
      font-weight: 12px;
    }
  }
}
.PhoneInput {
  .PhoneInputCountry {
    border-right: 1px solid #d4d8db !important;
    margin-right: 5px;
    padding-right: 8px;
    .PhoneInputCountryIcon {
      margin-right: 3px;
      border-radius: 2px;
    }
    .PhoneInputCountrySelectArrow {
      color: #3a3a3a !important;
      font-size: 18px;
    }
  }
  .PhoneInputInput,
  .PhoneInputInput:hover,
  .PhoneInputInput:focus,
  .PhoneInputInput:focus-visible {
    color: var(--primary200) !important;
    font-size: 12px;
    border-width: 0;
    padding-left: 5px;
    outline: none !important;
    outline: -webkit-focus-ring-color auto 0px !important;
    box-shadow: none !important;
  }
}
.react-tel-input {
  padding: 0 !important;
  border: 0 !important;
  .form-control {
    width: 100% !important;
    padding-left: 50px !important;
  }
  .flag-dropdown {
    padding: 0 3px 0 0;
    border: 1px solid #d4d8db !important;
    border-right: 0 !important;
    border-radius: 4px 0 0 4px !important;
    background-color: $white100 !important;
  }
  .selected-flag {
    width: 42px !important;
    height: 23px !important;
    border-right: 1px solid #d4d8db;
    margin: 4px 0;
    cursor: not-allowed !important;
  }
}
.disabled-phone {
  .react-tel-input {
    .flag-dropdown {
      background-color: #f2f5f6 !important;
    }
  }
}

.input-group {
  .input-group-prepend {
    .input-group-text {
      padding: 3px 10px 4px;
    }
    .form-control {
      position: initial;
    }
  }
}

body {
  input:-internal-autofill-selected {
    background-color: -internal-light-dark(
      rgb(59, 59, 59),
      rgb(59, 59, 59)
    ) !important;
    color: fieldtext !important;
  }
}

.btn-group-toggle .btn {
  border-radius: 0 !important;
  border-color: $grey700 !important;
  border: 0 !important;
  &:hover,
  &:focus {
    background-color: $transparent !important;
  }
}

.form-group {
  .small,
  small {
    font-size: 9px !important;
  }
  .file-upload {
    width: 100%;
  }
}
.input-group-text {
  height: 30px;
}
.input-group {
  .input-group-text {
    font-size: 12px !important;
  }
}
.password-input {
  -webkit-text-security: disc;
  text-security: disc;
}

.custom-switch-btn {
  position: relative;
  .form-check-input {
    width: 35px;
    border-color: $grey700 !important;
    background-color: $grey700 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    &:checked {
      border-color: $green75 !important;
        background-color: $green75 !important;
    }
  }
  .form-check-label {
    margin: 0;
    font-size: 10px;
    position: absolute;
    top: 6px;
    left: 22px;
    color: $white100;
  }
}

.home-away-option {
  .custom-switch-btn {
    .form-check-input {
      border-color: var(--primary100) !important;
      background-color: var(--primary100) !important;
      &:checked {
        border-color: var(--primary100) !important;
        background-color: var(--primary100) !important;
      }
    }
  }
}

.form-select-md {
  padding: 0px 34px 0px 8px !important;
  height: 26px !important;
  min-width: 80px;
  width: auto !important;
}

.addMoreBtn {
  font-size: 18px !important;
  color: var(--primary50) !important;
}
a.gridLink:link {
  color: var(--primary100);
  text-decoration: underline;
  display: inline-block !important;
}
.statsDiv {
  border: #d1d1d1 solid 1px;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 5px;
  //padding: 5px;
  background: #f1f1f1;
  .badge {
    font-size: 12px;
  } 
  .badge-warning {
    color: #000000;
    //background-color: #b2d4b9!important;
    background-color: var(--primary25)!important;
  }
  .badge-success{
    background-color: var(--primary100);
  }
}
